import React, { useEffect, useState } from 'react';
import axios from "axios";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import HrSidebar from './HrSidebar';
import Week from './HrWeek';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
 
const Notice = () => {

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
       if (!token) {
      navigate('/');
    } 
  }, []);
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'HrHome') {
        navigate('/');
      }
    }
  }, []);

  const [addNoticeOpen, setAddNoticeOpen] = useState(false);
  const [newNoticePeriod, setNewNoticePeriod] = useState("");
  const [duration, setDuration] = useState("");
  const [rowsPerPages, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorE1, setAnchorE1] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [data, setData] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedRow, setEditedRow] = useState({});
 
  const fetchData = () => {
    axios.get(`https://api.payroll.functionparks.com/cal/notice/${username}`)
    .then((response) => {
      setData(response.data);
    })
    .catch((error) => {
      console.error("Error fetching Assets data:", error);
    });
  }
  
  useEffect(() => {
    fetchData();
  }, [username]);
 
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  const handleClick = (event, row) => {
    setAnchorE1(event.currentTarget);
    setSelectedRow(row);
  };
  const handleClose = () => {
    setAnchorE1(null);
    setSelectedRow(null);
  };
 
 
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      handleClose();
      const response = await axios.put(
        `https://api.payroll.functionparks.com/notice/${editedRow.id}`,
 
        editedRow
      );
 
      toast.success("Record updated successfully");
      setData((prevData) =>
        prevData.map((item) => (item.id === editedRow.id ? editedRow : item))
      );
      handleClose();
      setEditDialogOpen(false);
      setEditedRow(null);
    } catch (error) {
      console.error("Error updating record:", error);
      toast.error("Failed to update record");
    }
  };

  const handleCloseAddNotice = () => {
    setAddNoticeOpen(false);
  };
  const handleCloseAddNoticeDialog = () => {
    setAddNoticeOpen(false);
  };
  const handleDeleteCancelled = () => {
    setDeleteConfirmationOpen(false);
  };
  const handleDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };
 
 
  const handleDeleteConfirmed = () => {
    console.log("Selected Row:", selectedRow);
    if (selectedRow && selectedRow.NoticePeriodName) {
      axios.delete(`https://api.payroll.functionparks.com/notice/${selectedRow.NoticePeriodName}`)
        .then(() => {
          setData(prevData => prevData.filter(row => row.NoticePeriodName !== selectedRow.NoticePeriodName));
          toast.success('Record deleted successfully');
      })
        .catch((error) => {
          console.error('Error deleting record:', error);
        });
    } else {
      console.error('No selected row or notice found.');
    }
    handleClose(); 
    handleDeleteCancelled(); 
  };  
 
 
  const handleEditFormChange = (event, field) => {
    const { value } = event.target;
    setEditedRow((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
 
  const handleEditOpen = (row) => {
    setEditedRow(row);
    setEditDialogOpen(true);
  };

  const handleAddNotice = () => {
    if (!newNoticePeriod || !duration) {
      toast.warning("Please fill in Name & Duration");
      return;
    }
    const newNotice = {
      NoticePeriodName: newNoticePeriod,
      NoticePeriodDuration: duration,
      officeId: username,
    };
    axios.post('https://api.payroll.functionparks.com/HE/notice/Post', newNotice)
      .then(() => {
        console.log('notice added successfully');
        toast.success('Notice added successfully');
        setNewNoticePeriod(""); 
        setDuration("");
        handleCloseAddNotice();
        fetchData();
      })
      .catch((error) => {
        console.error('Error adding notice:', error);
      });
  };
 
  const handleOpenNoticeDialog = () => {
    setAddNoticeOpen(true);
  };
 
  let filteredData = [...data];
 
  const totalPages = Math.ceil(filteredData.length / rowsPerPages);
  const startIndex = (currentPage - 1) * rowsPerPages;
  const endIndex = Math.min(startIndex + rowsPerPages, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);
 
  return (
    <>
    <HrSidebar/>
      <Week />
      <div style={{ overflowX: "auto", maxWidth: "100%", padding: "15px" }}>
  <div className="alien">
    <div className='moon'>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleOpenNoticeDialog}
      >
        + Add Notice Period
      </Button>
 
      <Dialog open={addNoticeOpen} onClose={handleCloseAddNotice}>
        <DialogTitle>Add New NoticePeriod</DialogTitle>
        <DialogContent>
          <TextField
            label="Notice Period"
            value={newNoticePeriod}
            onChange={(e) => setNewNoticePeriod(e.target.value)}
            fullWidth
            margin="normal"
          />
 
          <TextField
            select
            variant="outlined"
            label="Duration"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            fullWidth
            margin="normal"
          >
            <MenuItem value="30-Days">30-Days</MenuItem>
            <MenuItem value="60-Days">60-Days</MenuItem>
            <MenuItem value="90-Days">90-Days</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddNoticeDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddNotice} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  </div>
 
  <TableContainer component={Paper}>
    <Table aria-label="simple table">
      <TableHead style={{ backgroundColor: "rgb(167, 165, 165)" }}>
        <TableRow>
          <TableCell> Notice Period Name</TableCell>
          <TableCell>Notice Period Duration</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {currentData.map((row, index) => (
          <TableRow key={index}>
             <TableCell>
              {row.NoticePeriodName}
            </TableCell>
         
            <TableCell> {row.NoticePeriodDuration}</TableCell>
            <TableCell>
              <Button onClick={(event) => handleClick(event, row)}>
                <MoreHorizIcon />
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
 
  <div
    style={{
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "1rem",
    }}
  >
    <div>
      <Button disabled={currentPage === 1} onClick={handlePrevPage}>
        <FaChevronLeft />
      </Button>
      <span style={{ margin: "0 10px" }}>
        Page {currentPage} of {totalPages}
      </span>
      <Button
        disabled={currentPage === totalPages}
        onClick={handleNextPage}
      >
        <FaChevronRight />
      </Button>
    </div>
    <Typography variant="body2">
      {" "}
      <select
        value={rowsPerPages}
        onChange={handleRowsPerPageChange}
        style={{ width: "40px", height: "28px" }}
      >
        <option value={4}>4</option>
        <option value={8}>8</option>
        <option value={12}>12</option>
      </select>
    </Typography>
  </div>

    <Menu
      anchorEl={anchorE1}
      open={Boolean(anchorE1)}
      onClose={handleClose}>
        <MenuItem
            onClick={() => { handleEditOpen(selectedRow); handleClose();}}> Edit</MenuItem>
      <MenuItem onClick={handleDeleteConfirmation}>Delete</MenuItem>
    </Menu>
 
    <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
          <DialogTitle>Edit Record</DialogTitle>
          <DialogContent>
            <form onSubmit={handleFormSubmit}>

              <TextField
                label="Notice Period Name"
                value={editedRow ? editedRow.NoticePeriodName : ""}
                onChange={(event) =>
                  handleEditFormChange(event, "NoticePeriodName")
                }
                fullWidth
                margin="normal"
              />
              <Select
                label="Notice Period Duration"
                value={editedRow ? editedRow.NoticePeriodDuration : ""}
                onChange={(event) =>
                  handleEditFormChange(event, "NoticePeriodDuration")
                }
                fullWidth
                margin="normal"
              >
                <MenuItem value="30-Days">30-Days</MenuItem>
                <MenuItem value="60-Days">60-Days</MenuItem>
                <MenuItem value="90-Days">90-Days</MenuItem>

              </Select>
              <DialogActions>
                <Button
                  onClick={() => setEditDialogOpen(false)}
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>

  <Dialog open={deleteConfirmationOpen} onClose={handleDeleteCancelled}>
    <DialogTitle>Confirmation</DialogTitle>
    <DialogContent>
      Are you sure you want to delete this record?
    </DialogContent>
    <DialogActions>
      <Button onClick={handleDeleteCancelled} color="primary">
        Cancel
      </Button>
      <Button onClick={handleDeleteConfirmed} color="primary">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
</div>
<ToastContainer />
</>
  );
};
 
export default Notice;
 
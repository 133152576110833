import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";
 
const PayrollChart = () => {
  const [data, setData] = useState([]);
  const [username,setUsername] =useState([]);
  const [role, setRole] = useState("");
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const navigate = useNavigate();
 
  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "HrHome") {
      navigate("/");
    }
  }, [navigate]);
 
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "HrHome") {
        navigate("/");
      } else {
        setShowWelcomeMessage(true);
      }
    }
  }, [navigate]);
 
  useEffect(() => {
    axios.get(`https://api.payroll.functionparks.com/api/approved-leaves-per-month/${username}`)
      .then(response => {
        console.log('Fetched data:', response.data);
        const filledData = fillMissingMonths(response.data);
        setData(filledData);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [username]);
 
  // Function to fill in missing months with zero leave counts
  const fillMissingMonths = (originalData) => {
    const allMonths = generateMonthsArray();
    const filledData = allMonths.map(month => {
      const existingData = originalData.find(item => item.month === month);
      return existingData || { month, leaveCount: 0 };
    });
    return filledData;
  };
 
  // Function to generate an array of all months in a year
  const generateMonthsArray = () => {
    const months = [];
    for (let i = 1; i <= 12; i++) {
      months.push(`${new Date().getFullYear()}-${i.toString().padStart(2, '0')}`);
    }
    return months;
  };
 
  return (
    <>
      <h4 className='text-primary shadow-sm p-2 bg-body rounded' style={{ marginTop: "-50px" }}>Leave</h4>
      <div style={{ width: '100%', height: 300 }}>
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            {/* <Legend /> */}
            <Line type="monotone" dataKey="leaveCount" stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};
 
export default PayrollChart;
 
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Menu,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import moment from 'moment';
import Axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HrSidebar from './HrSidebar';
import Leave from './LeaveReqNav';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

const ApproveLeave = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
       if (!token) {
      navigate('/');
    } 
  }, []);
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'HrHome') {
        navigate('/');
      }
    }
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedLeaveType, setSelectedLeaveType] = useState(null);
  const [leavedata, setLeaveData] = useState([]);
  const [data, setData] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedRow, setEditedRow] = useState(null);

  useEffect(() => {
    if(username) {
      fetchDataLeave();
    }
  }, [username]);

  const handleLeaveTypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedLeaveType(selectedType);
  };

  const fetchDataLeave = () => {
    Axios.get(`https://api.payroll.functionparks.com/HR/Leavechange/type/${username}`)
      .then((response) => {
        setLeaveData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching Leave data:', error);
        toast.error('Failed to fetch Leave data');
      });
  };

  useEffect(() => {
    if (username) {
      fetchData();
    }
  }, [username]);

  const fetchData = async () => {
    try {
      const response = await Axios.get(`https://api.payroll.functionparks.com/api/country/${username}`);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to fetch data');
    }
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      handleClose();
      setEditDialogOpen(false);
      toast.info('Updating record...');

      const formattedStartDate = moment(editedRow.StartDate).format('YYYY-MM-DD');
      const response = await Axios.put(
        `https://api.payroll.functionparks.com/api/Leave/${editedRow.EmployeeID}/${formattedStartDate}`,
        editedRow
      );

      fetchData();
      

      toast.success('Record updated successfully');
    } catch (error) {
      console.error('Error updating record:', error);
      toast.error('Failed to update record');
    }
  };

  const handleEditFormChange = (event, field) => {
    const { value } = event.target;
    setEditedRow((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleEditOpen = (row) => {
    setEditedRow(row);
    setEditDialogOpen(true);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmed = () => {
    if (selectedRow) {
      const idToDelete = selectedRow.EmployeeID;
      const startDateToDelete = moment(selectedRow.StartDate).format('YYYY-MM-DD');

      Axios.delete(`https://api.payroll.functionparks.com/api/Leave34/${idToDelete}/${startDateToDelete}`)
        .then((response) => {
          const updatedLeaveRequest = data.filter(
            (item) =>
              !(item.EmployeeID === idToDelete && moment(item.StartDate).format('YYYY-MM-DD') === startDateToDelete)
          );
          setData(updatedLeaveRequest);
          setDeleteConfirmationOpen(false);
          handleClose();
          toast.success('Record deleted successfully');
        })
        .catch((error) => {
          console.error('Error deleting record:', error);
          toast.error('Failed to delete record');
        });
    }
  };

  const handleDeleteCancelled = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  let filteredData = [...data];

  if (searchQuery) {
    filteredData = filteredData.filter((item) => item.EmployeeID.toLowerCase() === searchQuery.toLowerCase());
  }

  if (selectedDate) {
    const formattedDate = moment(selectedDate).format('YYYY-MM-DD');
    filteredData = filteredData.filter((item) => moment(item.StartDate).format('YYYY-MM-DD') === formattedDate);
  }

  if (selectedLeaveType !== null && selectedLeaveType !== '') {
    filteredData = filteredData.filter((item) => item.LeaveType === selectedLeaveType);
  }

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <>
      <HrSidebar />
      <Leave/>
      <div className="main-container">
        {/* <h1 className="header1">Show Leave Requests</h1> */}
        <div style={{ overflowX: 'auto', maxWidth: '100%', padding: '15px' }}>
          <div className="typo">
            <Typography variant="body2" style={{ width: '200px', marginTop:"15px" }}>
              <TextField type="date"
               label="From Date"
                value={selectedDate} onChange={handleDateChange} 
                InputLabelProps={{
                  shrink: true,
                }}/>
            </Typography>
            <div style={{ display: 'flex' }}>
              <TextField
                select
                label="Leave Type"
                value={selectedLeaveType}
                onChange={handleLeaveTypeChange}
                fullWidth
                margin="normal"
              >
                <MenuItem value="">All</MenuItem>
                {leavedata.map((leave, index) => (
                  <MenuItem key={index} value={leave.LeaveType}>
                    {leave.LeaveType}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                variant="outlined"
                label="EmployeeID"
                value={searchQuery}
                onChange={handleSearchChange}
                className="text"
                style={{ marginLeft: '10px', marginTop: '15px' }}
              ></TextField>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead style={{ backgroundColor: 'rgb(167, 165, 165)' }}>
                <TableRow>
                  <TableCell
                  className="super-stickyCell">
                  Employee ID
                </TableCell>
                  <TableCell>Request On</TableCell>
                  <TableCell>From Date</TableCell>
                  <TableCell>TO Date</TableCell>
                  <TableCell>Count Days</TableCell>
                  <TableCell>Leave Type</TableCell>
                  <TableCell>Leave Status</TableCell>
                  <TableCell>Comment</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                    component="th"
                    scope="row"
                    className="super-stickyCell">
                    {row.EmployeeID}
                  </TableCell>
                    <TableCell>{moment(row.RequestedOn).format("DD/MM/YYYY")}</TableCell>
                    <TableCell>{moment(row.StartDate).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{moment(row.EndDate).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{row.TotalCount}</TableCell>
                    <TableCell>{row.LeaveType}</TableCell>
                    <TableCell>{row.LeaveStatus || "Pending"}</TableCell>
                    <TableCell>{row.Comments || 'None'}</TableCell>
                    <TableCell>
                      <Button onClick={(event) => handleClick(event, row)}>
                        <MoreHorizIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
            <div>
              <Button disabled={currentPage === 1} onClick={handlePrevPage}>
                <FaChevronLeft />
              </Button>
              <span style={{ margin: '0 10px' }}>Page {currentPage} of {totalPages}</span>
              <Button disabled={currentPage === totalPages} onClick={handleNextPage}>
                <FaChevronRight />
              </Button>
            </div>
            <Typography variant="body2">
              {' '}
              <select value={rowsPerPage} onChange={handleRowsPerPageChange} style={{ width: '40px', height: '28px' }}>
                <option value={4}>4</option>
                <option value={8}>8</option>
                <option value={12}>12</option>
              </select>
            </Typography>
          </div>

          {selectedRow && (
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem onClick={() => handleEditOpen(selectedRow)}>Edit</MenuItem>
              <MenuItem onClick={handleDeleteConfirmation}>Delete</MenuItem>
            </Menu>
          )}

          <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
            <DialogTitle>Edit Details</DialogTitle>
            <DialogContent>
              <form onSubmit={handleFormSubmit}>
                <TextField
                  label="Employee ID"
                  value={editedRow ? editedRow.EmployeeID : ''}
                  onChange={(e) => handleEditFormChange(e, 'EmployeeID')}
                  fullWidth
                  margin="normal"
                  disabled
                />
                <TextField
                  label="Start Date"
                  type="date"
                  value={editedRow ? moment(editedRow.StartDate).format('YYYY-MM-DD') : ''}
                  onChange={(e) => handleEditFormChange(e, 'StartDate')}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="End Date"
                  type="date"
                  value={editedRow ? moment(editedRow.EndDate).format('YYYY-MM-DD') : ''}
                  onChange={(e) => handleEditFormChange(e, 'EndDate')}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Leave Type"
                  select
                  value={editedRow ? editedRow.LeaveType : ''}
                  onChange={(e) => handleEditFormChange(e, 'LeaveType')}
                  fullWidth
                  margin="normal"
                >
                  {leavedata.map((leave, index) => (
                    <MenuItem key={index} value={leave.LeaveType}>
                      {leave.LeaveType}
                    </MenuItem>
                  ))}
                </TextField>
                
                <TextField
                  label="Leave Status"
                  select
                  value={editedRow ? editedRow.LeaveStatus : ''}
                  onChange={(e) => handleEditFormChange(e, 'LeaveStatus')}
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value="Approved">Approved</MenuItem>
                  <MenuItem value="Rejected">Rejected</MenuItem>
                </TextField>
                <TextField
                  label="Comments"
                  value={editedRow ? editedRow.Comments : ''}
                  onChange={(e) => handleEditFormChange(e, 'Comments')}
                  fullWidth
                  margin="normal"
                />
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setEditDialogOpen(false)} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleFormSubmit} color="primary">
                Update
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={deleteConfirmationOpen} onClose={handleDeleteCancelled}>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>Are you sure you want to delete this record?</DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteCancelled} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDeleteConfirmed} color="primary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default ApproveLeave;


import React, { Component, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Menu,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
} from "@mui/material";
import { FaEllipsisV, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import AdminNavBar from "./AdminPayroll";
import AdminSidebar from "./AdminSidebar";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const AdminComponents = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "AdminHome") {
        navigate("/");
      }
    }
  }, []);

  const [selectedType, setSelectedType] = useState(null);
  const [addComponentOpen, setAddComponentOpen] = useState(false);
  const [newComponentName, setNewComponentName] = useState("");
  const [newComponentType, setNewComponentType] = useState("");
  const [newComponentValue, setNewComponentValue] = useState("");
  const [newValueType, setNewValueType] = useState("");
  const [newComponentID, setNewComponentID] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [data, setData] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [editedComponent, setEditedComponent] = useState(null);

  const fetchData = async () => {
    try {
      const response = await Axios.get(`https://api.payroll.functionparks.com/api/component/${username}`);
      if (response && response.data) {
        setData(response.data);
      } else {
        throw new Error("Invalid response structure");
      }
    } catch (error) {
      console.error("Error fetching Assets data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [username]);

  const handleOpenAddComponentDialog = () => {
    setAddComponentOpen(true);
  };

  const handleCloseAddComponentDialog = () => {
    setAddComponentOpen(false);
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };
  const handleDeleteCancelled = () => {
    setDeleteConfirmationOpen(false);
  };
  const handleDeleteConfirmed = () => {
    if (selectedRow) {
      const idToDelete = selectedRow.ComponentID;
      Axios.delete(`https://api.payroll.functionparks.com/api/component/delete/${idToDelete}`)
        .then((response) => {
          const updatedData = data.filter(
            (item) => item.ComponentID !== idToDelete
          );
          setData(updatedData);
          setDeleteConfirmationOpen(false);
          fetchData();
          handleClose();
          toast.success("Record deleted successfully");
        })
        .catch((error) => {
          console.error("Error deleting record:", error);
          toast.error("Failed to delete record");
        });
    }
  };

  const handleEdit = (row) => {
    setEditedComponent(row);
    setEditOpen(true);
  };

  const handleEditFormChange = (event, field) => {
    const value = event.target.value;
    setEditedComponent((prevEditedComponent) => ({
      ...prevEditedComponent,
      [field]: value,
    }));
  };

  const handleEditSubmit = () => {
    Axios.put(
      `https://api.payroll.functionparks.com/api/component/update/${editedComponent.ComponentID}`,
      editedComponent
    )
      .then((response) => {
        handleClose();
        fetchData();
        toast.success("Record updated successfully");
        setEditOpen(false);
      })
      .catch((error) => {
        console.error("Error updating record:", error);
        toast.error("Failed to update record");
      });
  };

  const handleAddComponent = () => {
    if(!newComponentName || !newComponentType || !newComponentValue || !newValueType) {
      toast.warning("Please fill in ComponentName, ComponentType, ComponentValue, ValueType");
      return;
    }
    Axios.post("https://api.payroll.functionparks.com/api/component/insert", {
      ComponentID: newComponentID,
      ComponentName: newComponentName,
      ComponentType: newComponentType,
      ComponentValue: newComponentValue,
      ValueType: newValueType,
      officeID: username,
    })
      .then((response) => {
        console.log("Component Added Successfully:", response.data);
        const newComponent = {
          ComponentName: newComponentName,
          ComponentType: newComponentType,
          ComponentValue: newComponentValue,
          ValueType: newValueType,
        };
        setData((preData) => [...data, newComponent]);
        setNewComponentName("");
        setNewComponentType("");
        setNewComponentValue("");
        setNewValueType("");
        handleCloseAddComponentDialog();
        fetchData();
        toast.success("Component added successfully");
      })
      .catch((error) => {
        console.error("Error adding new Component:", error);
        toast.error("Failed to add Component");
      });
  };

  let filteredData = [...data];

  if (selectedType) {
    filteredData = filteredData.filter((item) => item.type === selectedType);
  }

  if (searchQuery) {
    filteredData = filteredData.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <>
      <AdminSidebar />
      <AdminNavBar />
      <div
        style={{
          overflowX: "auto",
          maxWidth: "100%",
          padding: "10px",
          marginLeft: "10px",
        }}
      >
        <div className="super-typo">
          <div className="moon">
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleOpenAddComponentDialog}
            >
              + Add Component
            </Button>

            <Dialog
              open={addComponentOpen}
              onClose={handleCloseAddComponentDialog}
            >
              <DialogTitle>Add New Component</DialogTitle>
              <DialogContent>
                <TextField
                  label="Component Name"
                  value={newComponentName}
                  onChange={(e) => setNewComponentName(e.target.value)}
                  fullWidth
                  margin="normal"
                />

                <TextField
                  label="Component Type"
                  select
                  value={newComponentType}
                  onChange={(e) => setNewComponentType(e.target.value)}
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="Allowance">Allowance</MenuItem>
                  <MenuItem value="Deduction">Deduction</MenuItem>
                  <MenuItem value="Bonus">Bonus</MenuItem>
                  <MenuItem value="Tax">Tax</MenuItem>
                </TextField>

                <TextField
                  label="Component Value"
                  value={newComponentValue}
                  onChange={(e) => setNewComponentValue(e.target.value)}
                  fullWidth
                  margin="normal"
                />

                <TextField
                  label="Value Type"
                  select
                  value={newValueType}
                  onChange={(e) => setNewValueType(e.target.value)}
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value="%">Percentage</MenuItem>
                  <MenuItem value="units">Units</MenuItem>
                </TextField>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseAddComponentDialog}
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button onClick={handleAddComponent} color="primary">
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead style={{ backgroundColor: "rgb(167, 165, 165)" }}>
              <TableRow>
                {/* <TableCell>Component ID</TableCell> */}
                <TableCell>Component Name</TableCell>
                <TableCell>Component Type</TableCell>
                <TableCell>Component Value</TableCell>
                <TableCell>Value Type </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.map((row) => (
                <TableRow key={row}>
                  {/* <TableCell>{row.ComponentID}</TableCell> */}
                  <TableCell>{row.ComponentName}</TableCell>
                  <TableCell>{row.ComponentType}</TableCell>
                  <TableCell>{row.ComponentValue}</TableCell>
                  <TableCell>{row.ValueType}</TableCell>
                  <TableCell>
                    <Button onClick={(event) => handleClick(event, row)}>
                      <MoreHorizIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <div>
            <Button disabled={currentPage === 1} onClick={handlePrevPage}>
              <FaChevronLeft />
            </Button>
            <span style={{ margin: "0 10px" }}>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
            >
              <FaChevronRight />
            </Button>
          </div>
          <Typography variant="body2">
            {" "}
            <select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              style={{ width: "40px", height: "28px" }}
            >
              <option value={4}>4</option>
              <option value={8}>8</option>
              <option value={12}>12</option>
            </select>
          </Typography>
        </div>

        {selectedRow && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleEdit(selectedRow)}>Edit</MenuItem>
            <MenuItem onClick={handleDeleteConfirmation}>Delete</MenuItem>
          </Menu>
        )}

        <Dialog open={editOpen} onClose={() => setEditOpen(false)}>
          <DialogTitle>Edit Component</DialogTitle>
          <DialogContent>
            <TextField
              label="Component Name"
              value={editedComponent ? editedComponent.ComponentName : ""}
              onChange={(e) => handleEditFormChange(e, "ComponentName")}
              fullWidth
              margin="normal"
            />
            <Select
              label="Component Type"
              value={editedComponent ? editedComponent.ComponentType : ""}
              onChange={(e) => handleEditFormChange(e, "ComponentType")}
              fullWidth
              margin="normal"
            >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="Allowance">Allowance</MenuItem>
                  <MenuItem value="Deduction">Deduction</MenuItem>
                  <MenuItem value="Bonus">Bonus</MenuItem>
                  <MenuItem value="Tax">Tax</MenuItem>
            </Select>

            <TextField
              label="Component Value"
              value={editedComponent ? editedComponent.ComponentValue : ""}
              onChange={(e) => handleEditFormChange(e, "ComponentValue")}
              fullWidth
              margin="normal"
            />

            <TextField
              label="Value Type"
              select
              value={editedComponent ? editedComponent.ValueType : ""}
              onChange={(e) => handleEditFormChange(e, "ValueType")}
              fullWidth
              margin="normal"
            >
              <MenuItem value="%">Percentage</MenuItem>
              <MenuItem value="Units">Units</MenuItem>
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleEditSubmit} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={deleteConfirmationOpen} onClose={handleDeleteCancelled}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this record?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancelled} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirmed} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer />
    </>
  );
};

export default AdminComponents;

import React, { useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './AdminPayroll.css';
 
const Nav = [
    {
      path: "/adminPaying",
      name: "Payroll",
    },
    {
      path: "/AdminComponents",
      name: "Pay Component",
    },
    {
      path: "/AttendanceDetails",
      name: "AttendanceDetails",
    },
    {
      path: "/Payment",
      name: "Generate Payroll",
    },
    {
      path: "/SalaryDetails",
      name: "SalaryDetails"
    },
    {
      path:"/AllPayslips",
      name:"PaySlip"
    },

  ];
 
 
const Navbar = () => {
  const location = useLocation();
  const navRef = useRef(null);
 
  useEffect(() => {
    const activeLink = navRef.current.querySelector('.active');
    if (activeLink) {
      const { offsetLeft, offsetWidth } = activeLink;
      const { offsetWidth: navWidth, scrollLeft } = navRef.current;
      const activeLinkCenter = offsetLeft + offsetWidth / 2;
      const navCenter = scrollLeft + navWidth / 2;
      navRef.current.scrollTo({
        left: scrollLeft + (activeLinkCenter - navCenter),
        behavior: 'smooth',
      });
    }
  }, [location]);

  return (
    <>
    <div>
    <div className='hori' ref={navRef} style={{overflow:"auto", padding:'15px'}}>
    <style>
          {`
          .hori::-webkit-scrollbar {
              height: 4px;
            }
            .hori::-webkit-scrollbar-thumb {
              background: #888; 
            }
            .hori::-webkit-scrollbar-thumb:hover {
              background: #555; 
            }
            .font {
              display: inline-block;
              padding: 10px;
            }
        `}
        </style>
        {Nav.map((Navbar, index) => (
          <NavLink
            key={index}
            to={Navbar.path}
            activeClassName="active" >
            <div className='font'>{Navbar.name}</div>
          </NavLink>
        ))}
      </div>
    </div>
    </>
  )
}
 
export default Navbar;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavbarEmp from './HrNavbarEmp';
import HrSidebar from './HrSidebar';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Menu,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import moment from 'moment';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DataGrid = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'HrHome') {
        navigate('/');
      }
    }
  }, []);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDepartmentType, setSelectedDepartmentType] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editRowData, setEditRowData] = useState(null);
  const [departmentData, setDepartmentData] = useState([]);
  const [positionData, setPositionData] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [weekOffData, setWeekOffData] = useState([]);
  const [noticePeriodData, setNoticePeriodData] = useState([]);
  const [payrollTypes, setPayrollTypes] = useState([]);
  const [payComponents, setPayComponents] = useState([]);
  const [typeName, setTypeName] = useState('');
  const [payrollType, setPayrollType] = useState('');
  const [actionType, setActionType] = useState('');
  const [blockConfirmationOpen, setBlockConfirmationOpen] = useState(false);

  const [data, setData] = useState([]);

  const fetchData = () => {
    Axios.get(`https://api.payroll.functionparks.com/api/Employee/details/${username}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    if (username) {
      fetchData();
    }
  }, [username]);

  useEffect(() => {
    Axios.get(`https://api.payroll.functionparks.com/department/none/${username}`)
      .then((response) => {
        setDepartmentData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching department data:', error);
      });

    Axios.get(`https://api.payroll.functionparks.com/api/position/type/${username}`)
      .then((response) => {
        setPositionData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching position data:', error);
      });

    Axios.get(`https://api.payroll.functionparks.com/HR/shift/type/${username}`)
      .then((response) => {
        setShiftData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching shift data:', error);
      });

    Axios.get(`https://api.payroll.functionparks.com/HR/weekoff/type/${username}`)
      .then((response) => {
        setWeekOffData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching week off data:', error);
      });

    Axios.get(`https://api.payroll.functionparks.com/HR/Notice/type/${username}`)
      .then((response) => {
        setNoticePeriodData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching notice period data:', error);
      });
    // Axios.get(`https://api.payroll.functionparks.com/HR/data/type/${username}`)
    //   .then((response) => {
    //     setPayrollTypes(response.data);
    //   })
    //   .catch((error) => {
    //     console.error('Error fetching Payroll data:', error);
    //   });
  }, [username]);

  useEffect(() => {
    if (username) {
      fetchPayrollTypes();
      fetchPayComponents();
    }
  }, [username]);

  const fetchPayrollTypes = () => {
    Axios.get(`https://api.payroll.functionparks.com/HR/data/type/${username}`)
      .then((response) => {
        setPayComponents([]);
        setPayrollType('');
        setPayrollTypes(response.data);
      })
      .catch((error) => {
        console.error('Error fetching payroll types:', error);
        toast.error('Failed to fetch payroll types');
      });
  };
  const fetchPayComponents = (payrollType) => {
    Axios.get(`https://api.payroll.functionparks.com/HR/components/${payrollType}/${username}`)
      .then((response) => {
        setPayComponents(response.data);
      })
      .catch((error) => {
        console.error('Error fetching pay components:', error);
        // toast.error("Failed to fetch pay components");
      });
  };

  const handlePayrollTypeChange = (e) => {
    const selectedPayrollType = e.target.value;
    setEditRowData((prevData) => ({
      ...prevData,
      payrollType: selectedPayrollType,
    }));
    // setPayrollType(selectedPayrollType);
    const selectedType = payrollTypes.find(
      (type) => type.PayComponents === selectedPayrollType
    );
    setTypeName(selectedType?.TypeName || '');
    fetchPayComponents(selectedPayrollType);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handlePrePage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleEdit = () => {
    setEditRowData(selectedRow);
    setEditDialogOpen(true);
    handleClose();
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setEditRowData(null);
  };

  // const handleEditConfirmed = () => {
  //   if (editRowData) {
  //     Axios.put(
  //       `https://api.payroll.functionparks.com/api/updateEmployee/${editRowData.EmployeeID}`,
  //       editRowData
  //     )
  //       .then((response) => {
  //         const updatedData = data.map((item) =>
  //           item.id === editRowData.EmployeeID ? editRowData : item
  //         );
  //         setData(updatedData);
  //         setEditDialogOpen(false);
  //         setEditRowData(null);
  //         toast.success('Updated Successfully');
  //         fetchData();
  //       })
  //       .catch((error) => {
  //         console.error('Error updating data:', error);
  //       });
  //   }
  // };

  const handleEditConfirmed = () => {
    if (editRowData) {
      Axios.put(
        `https://api.payroll.functionparks.com/api/updateEmployee/${editRowData.EmployeeID}`,
        {
          ...editRowData,
          payComponents,
          typeName,
        }
      )
        .then((response) => {
          const updatedData = data.map((item) =>
            item.EmployeeID === editRowData.EmployeeID
              ? {
                  ...item,
                  ...editRowData,
                  // payComponents,
                }
              : item
          );
          setData(updatedData);
          setEditDialogOpen(false);
          setEditRowData(null);
          toast.success('Updated Successfully');
          fetchData();
        })
        .catch((error) => {
          console.error('Error updating data:', error);
        });
    }
  };

  const handleDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmed = () => {
    if (selectedRow) {
      const idToDelete = selectedRow.EmployeeID;
      Axios.delete(`https://api.payroll.functionparks.com/api/employee/delete/${idToDelete}`)
        .then((response) => {
          const updatedData = data.filter(
            (item) => item.EmployeeID !== idToDelete
          );
          setData(updatedData);
          setDeleteConfirmationOpen(false);
          handleClose();
          toast.success('Record Deleted Successfully');
        })
        .catch((error) => {
          console.error('Error deleting data:', error);
        });
    }
  };

  const handleDeleteCancelled = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleBlockCancelled = () => {
    setBlockConfirmationOpen(false);
  };

  const handleBlockUnblock = () => {
    if (selectedRow) {
      const newStatus =
        selectedRow.EmployeeStatus === 'Blocked' ? 'Active' : 'Blocked';

      Axios.put(
        `https://api.payroll.functionparks.com/api/blockUnblock/${selectedRow.EmployeeID}`,
        {
          EmployeeStatus: newStatus,
        }
      )
        .then((response) => {
          const updatedData = data.map((item) =>
            item.EmployeeID === selectedRow.EmployeeID
              ? { ...item, EmployeeStatus: newStatus }
              : item
          );
          setData(updatedData);
          setBlockConfirmationOpen(false);
          toast.success(
            `Employee ${
              newStatus === 'Blocked' ? 'Blocked' : 'Unblocked'
            } Successfully`
          );
          handleClose();
        })
        .catch((error) => {
          console.error(
            `Error ${
              newStatus === 'Blocked' ? 'blocking' : 'unblocking'
            } employee:`,
            error
          );
          toast.error(
            `Failed to ${
              newStatus === 'Blocked' ? 'block' : 'unblock'
            } employee`
          );
        });
    }
  };

  let filteredData = [...data];

  if (selectedDate) {
    filteredData = filteredData.filter((item) =>
      moment(item.date).isSame(selectedDate, 'day')
    );
  }

  if (searchQuery) {
    filteredData = filteredData.filter(
      (item) =>
        item.DisplayName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.EmployeeID.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <>
      <HrSidebar />
      <NavbarEmp />

      <div style={{ overflowX: 'auto', maxWidth: '100%', padding: '15px' }}>
        <div className="typo">
          <div className="caves">
            <TextField
              variant="outlined"
              label="Search"
              value={searchQuery}
              onChange={handleSearchChange}
              style={{ marginLeft: '10px' }}
            ></TextField>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead style={{ backgroundColor: 'rgb(167, 165, 165)' }}>
              <TableRow>
                <TableCell
                  className="super-stickyCell"
                  style={{
                    overflow: 'auto',
                    borderRight: '2px solid lightblue',
                    backgroundColor: 'white',
                  }}
                >
                  Employee ID
                </TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Middle Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>DOB</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>City</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Postal Code</TableCell>
                <TableCell>Reporting</TableCell>
                <TableCell>Reporting To</TableCell>
                <TableCell>Job Position</TableCell>
                <TableCell>Job Department</TableCell>
                <TableCell>Shifts</TableCell>
                <TableCell>Weekly Offs</TableCell>
                <TableCell>Notice Period</TableCell>
                <TableCell>Basic Salary</TableCell>
                <TableCell>Payroll Type</TableCell>
                <TableCell>Total Salary</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            {/* <TableBody>
              {currentData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    component="th"
                    scope="row"
                    className="super-stickyCell"
                    style={{
                      overflow: 'auto',
                      borderRight: '2px solid lightblue',
                      backgroundColor: 'white',
                    }}
                  >
                    {' '}
                    {row.EmployeeID}
                  </TableCell>
                  <TableCell>{row.FirstName}</TableCell>
                  <TableCell>{row.MiddleName}</TableCell>
                  <TableCell>{row.LastName}</TableCell>
                  <TableCell>{row.Gender}</TableCell>
                  <TableCell>
                    {moment(row.DateOfBirth).format('DD-MM-YYYY')}
                  </TableCell>
                  <TableCell>{row.Email}</TableCell>
                  <TableCell>{row.PhoneNumber}</TableCell>
                  <TableCell>{row.city}</TableCell>
                  <TableCell>{row.State}</TableCell>
                  <TableCell>{row.PostalCode}</TableCell>
                  <TableCell>{row.Reporting}</TableCell>
                  <TableCell>{row.Repoting_to}</TableCell>
                  <TableCell>{row.JobTitle}</TableCell>
                  <TableCell>{row.JobDepartment}</TableCell>
                  <TableCell>{row.Shifts}</TableCell>
                  <TableCell>{row.WeeklyOffs}</TableCell>
                  <TableCell>{row.NoticePeriod}</TableCell>
                  <TableCell>{row.BasicSalary}</TableCell>
                  <TableCell>{row.TypeName}</TableCell>
                  <TableCell>{row.TotalSalary}</TableCell>
                  <TableCell>
                    <div style={{ display: 'flex', gap: '8px' }}>
                      <Button onClick={(event) => handleClick(event, row)}>
                        <MoreHorizIcon />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody> */}

            <TableBody>
              {currentData.map((row, index) => (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor:
                      row.EmployeeStatus === 'Blocked'
                        ? '#f0f0f9'
                        : 'transparent',
                    pointerEvents:
                      row.EmployeeStatus === 'Blocked' ? 'none' : 'auto',
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className="super-stickyCell"
                    style={{
                      overflow: 'auto',
                      borderRight: '2px solid lightblue',
                      backgroundColor: 'white',
                      pointerEvents:
                        row.EmployeeStatus === 'Blocked' ? 'none' : 'auto',
                    }}
                  >
                    {row.EmployeeID}
                  </TableCell>
                  <TableCell>{row.FirstName}</TableCell>
                  <TableCell>{row.MiddleName}</TableCell>
                  <TableCell>{row.LastName}</TableCell>
                  <TableCell>{row.Gender}</TableCell>
                  <TableCell>
                    {moment(row.DateOfBirth).format('DD-MM-YYYY')}
                  </TableCell>
                  <TableCell>{row.Email}</TableCell>
                  <TableCell>{row.PhoneNumber}</TableCell>
                  <TableCell>{row.city}</TableCell>
                  <TableCell>{row.State}</TableCell>
                  <TableCell>{row.PostalCode}</TableCell>
                  <TableCell>{row.Reporting}</TableCell>
                  <TableCell>{row.Repoting_to}</TableCell>
                  <TableCell>{row.JobTitle}</TableCell>
                  <TableCell>{row.JobDepartment}</TableCell>
                  <TableCell>{row.Shifts}</TableCell>
                  <TableCell>{row.WeeklyOffs}</TableCell>
                  <TableCell>{row.NoticePeriod}</TableCell>
                  <TableCell>{row.BasicSalary}</TableCell>
                  <TableCell>{row.TypeName}</TableCell>
                  <TableCell>{row.TotalSalary}</TableCell>
                  <TableCell style={{ pointerEvents: 'auto' }}>
                    <div style={{ display: 'flex', gap: '8px' }}>
                      <Button onClick={(event) => handleClick(event, row)}>
                        <MoreHorizIcon />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
          }}
        >
          <div>
            <Button disabled={currentPage === 1} onClick={handlePrePage}>
              <FaChevronLeft />
            </Button>
            <span style={{ margin: '0 10px', color: 'black' }}>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
            >
              <FaChevronRight />
            </Button>
          </div>
          <Typography variant="body2">
            {''}
            <select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              style={{ width: '40px', height: '28px' }}
            >
              <option value={4}>4</option>
              <option value={8}>8</option>
              <option value={12}>12</option>
            </select>
          </Typography>
        </div>

        {selectedRow && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem onClick={handleDeleteConfirmation}>Delete</MenuItem>
            <MenuItem
              onClick={() => {
                // Update the block confirmation handler to handle both block and unblock actions
                setBlockConfirmationOpen(true);
                setActionType(
                  selectedRow.EmployeeStatus === 'Blocked' ? 'Unblock' : 'Block'
                );
              }}
            >
              {selectedRow.EmployeeStatus === 'Blocked' ? 'Unblock' : 'Block'}
            </MenuItem>
          </Menu>
        )}

        <Dialog open={deleteConfirmationOpen} onClose={handleDeleteCancelled}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this record?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancelled} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirmed} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={blockConfirmationOpen} onClose={handleBlockCancelled}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            {actionType === 'Block'
              ? 'Are you sure you want to Block this record?'
              : 'Are you sure you want to Unblock this record?'}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleBlockCancelled} color="primary">
              Cancel
            </Button>
            <Button onClick={handleBlockUnblock} color="primary">
              {actionType}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
          <DialogTitle>Edit Record</DialogTitle>
          <DialogContent>
            <TextField
              label="First Name"
              value={editRowData ? editRowData.FirstName : ''}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  FirstName: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: '20px', marginTop: '15px' }}
            />
            <TextField
              label="Middle Name"
              value={editRowData ? editRowData.MiddleName : ''}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  MiddleName: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: '20px' }}
            />
            <TextField
              label="Last Name"
              value={editRowData ? editRowData.LastName : ''}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  LastName: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: '20px' }}
            />
            <FormControl fullWidth style={{ marginBottom: '20px' }}>
              <InputLabel shrink>Gender</InputLabel>
              <Select
                label="Gender"
                value={editRowData ? editRowData.Gender : ''}
                onChange={(e) =>
                  setEditRowData((prevData) => ({
                    ...prevData,
                    Gender: e.target.value,
                  }))
                }
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Date of Birth"
              value={
                editRowData
                  ? moment(editRowData.DateOfBirth).format('YYYY-MM-DD')
                  : ''
              }
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  DateOfBirth: e.target.value,
                }))
              }
              type="date"
              fullWidth
              style={{ marginBottom: '20px' }}
            />

            <TextField
              label="Email"
              value={editRowData ? editRowData.Email : ''}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  Email: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: '20px' }}
            />

            <TextField
              label="Phone Number"
              value={editRowData ? editRowData.PhoneNumber : ''}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  PhoneNumber: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: '20px' }}
            />
            <TextField
              label="City"
              value={editRowData ? editRowData.city : ''}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  City: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: '20px' }}
            />
            <TextField
              label="State"
              value={editRowData ? editRowData.State : ''}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  State: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: '20px' }}
            />
            <TextField
              label="PostalCode"
              value={editRowData ? editRowData.PostalCode : ''}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  PostalCode: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: '20px' }}
            />
            <TextField
              label="Reporting"
              value={editRowData ? editRowData.Reporting : ''}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  Reporting: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: '20px' }}
            />
            <TextField
              label="Reporting_to"
              value={editRowData ? editRowData.Repoting_to : ''}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  Repoting_to: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: '20px' }}
            />
            <FormControl fullWidth style={{ marginBottom: '20px' }}>
              <InputLabel shrink>Position</InputLabel>
              <Select
                label="Position"
                value={editRowData ? editRowData.JobTitle : ''}
                onChange={(e) =>
                  setEditRowData((prevData) => ({
                    ...prevData,
                    JobTitle: e.target.value,
                  }))
                }
              >
                {positionData.map((option) => (
                  <MenuItem
                    key={option.PositionName}
                    value={option.PositionName}
                  >
                    {option.PositionName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginBottom: '20px' }}>
              <InputLabel shrink>Department</InputLabel>
              <Select
                label="Department"
                value={editRowData ? editRowData.JobDepartment : ''}
                onChange={(e) =>
                  setEditRowData((prevData) => ({
                    ...prevData,
                    JobDepartment: e.target.value,
                  }))
                }
              >
                {departmentData.map((option) => (
                  <MenuItem
                    key={option.DepartmentName}
                    value={option.DepartmentName}
                  >
                    {option.DepartmentName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginBottom: '20px' }}>
              <InputLabel shrink>Shifts</InputLabel>
              <Select
                label="Shifts"
                value={editRowData ? editRowData.Shifts : ''}
                onChange={(e) =>
                  setEditRowData((prevData) => ({
                    ...prevData,
                    Shifts: e.target.value,
                  }))
                }
              >
                {shiftData.map((shift) => (
                  <MenuItem key={shift.ShiftName} value={shift.ShiftName}>
                    {shift.ShiftName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginBottom: '20px' }}>
              <InputLabel shrink>Weekly Offs</InputLabel>
              <Select
                label="Weekly Offs"
                value={editRowData ? editRowData.WeeklyOffs : ''}
                onChange={(e) =>
                  setEditRowData((prevData) => ({
                    ...prevData,
                    WeeklyOffs: e.target.value,
                  }))
                }
              >
                {weekOffData.map((weekOff) => (
                  <MenuItem key={weekOff.Day} value={weekOff.Day}>
                    {weekOff.Day}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginBottom: '20px' }}>
              <InputLabel shrink>Notice Period</InputLabel>
              <Select
                label="Notice Period"
                value={editRowData ? editRowData.NoticePeriod : ''}
                onChange={(e) =>
                  setEditRowData((prevData) => ({
                    ...prevData,
                    NoticePeriod: e.target.value,
                  }))
                }
              >
                {noticePeriodData.map((noticePeriod) => (
                  <MenuItem
                    key={noticePeriod.NoticePeriodDuration}
                    value={noticePeriod.NoticePeriodDuration}
                  >
                    {noticePeriod.NoticePeriodDuration}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="BasicSalary"
              value={editRowData ? editRowData.BasicSalary : ''}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  BasicSalary: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: '20px' }}
            />

            <FormControl fullWidth style={{ marginBottom: '30px' }}>
              <InputLabel shrink={true}>Payroll Type</InputLabel>
              <Select
                label="Payroll Type"
                value={editRowData ? editRowData.payrollType : ''}
                onChange={handlePayrollTypeChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Type
                </MenuItem>
                {payrollTypes.map((type, index) => (
                  <MenuItem key={index} value={type.PayComponents}>
                    {type.TypeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="body2" style={{ color: '#888' }}>
              Whenever the page is reloaded or a new entry appears, assign the
              payroll type if there are any changes in basic salaries;
              otherwise, do not assign.
            </Typography>

            {/* <Select
        label="Payroll Type"
        value={editRowData ? editRowData.payroll : ""}
        onChange={(e) =>
          setEditRowData((prevData) => ({
            ...prevData,
            payroll: e.target.value,
          }))
        }
        fullWidth
        style={{ marginBottom: "20px" }}
      >
        {payrollTypes.map((type) => (
          <MenuItem key={type.TypeName} value={type.PayComponents}>
            {type.TypeName}
          </MenuItem>
        ))}
      </Select> */}

            {/* 
            <TextField
              label="TotalSalary"
              value={editRowData ? editRowData.totalSalary : ""}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  totalSalary: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: "20px" }}
            /> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEditConfirmed} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
        <ToastContainer />
      </div>
    </>
  );
};

export default DataGrid;

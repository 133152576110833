import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BsFillGrid3X3GapFill, BsPeopleFill, BsListCheck, BsEmojiSunglasses, BsRepeat, BsJustify } from 'react-icons/bs';
import { MdLogout, MdHome } from 'react-icons/md';
import { BsEscape } from 'react-icons/bs';
// import { GiReceiveMoney } from 'react-icons/gi';
import { IoIosNotifications } from "react-icons/io";
import { TbListDetails } from "react-icons/tb";
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';
import { useHighlightCriteria } from "../../../src/Hr/components/HR/HighlightCriteriaContext";


function NotificationPopup({ notifications, onNotificationClick, onClose, onClearAll }) {
  const handleClearAll = () => {
    onClearAll(); 
    onClose(); 
  };
  return (
    <div className="notification-panel">
      <h4 className="user-name">Notifications</h4>
      <div className="notifications-container">
        {notifications.map((notification, index) => (
          <div
            key={index}
            className="notification"
            onClick={() => onNotificationClick(index)}>
            {notification}
          </div>
        ))}
      </div>
      <div style={{display:"flex", justifyContent:"space-between", marginTop:"15px"}}>
      <button onClick={onClose} style={{ color: "white" }}>
        Close
      </button>
      <button onClick={handleClearAll} style={{ color: "white" }}>
        Clear All
      </button>
      </div>
    </div>
  );
}


function Sidebar() {
  const sidebarRef = useRef(null);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const { setHighlightCriteria } = useHighlightCriteria();


  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    }
  }, [navigate]);
 
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "AdminHome") {
        navigate("/");
      }
    }
  }, [navigate]);

  useEffect(() => {
    const clearedNotifications = JSON.parse(localStorage.getItem('clearedNotifications')) || [];
    fetchNotifications(clearedNotifications);
  }, [username]);

  const fetchNotifications = async (clearedNotifications) => {
    try {
      const [leaveResponse, attendanceResponse] = await Promise.all([
        axios.get(`https://api.payroll.functionparks.com/admin/pendingNotifications/${username}`),
        axios.get(`https://api.payroll.functionparks.com/admin/Notifications/${username}`),
      ]);
  
      const leaveNotifications = leaveResponse.data.map((notification) => {
        const startDate = new Date(notification.StartDate).toLocaleDateString();
        const endDate = new Date(notification.EndDate).toLocaleDateString();
        return (
          <Link
            key={`${notification.EmployeeID}-${notification.StartDate}-${notification.EndDate}`}
            to="/AdminPaid"
            className="notification-link"
            startDate={notification.StartDate}
            endDate={notification.EndDate}>
            Leave Request {notification.EmployeeID} from {startDate} to {endDate}
          </Link>
        );
      });
  
      const attendanceNotifications = attendanceResponse.data.map((notification) => {
        const date = new Date(notification.Date).toLocaleDateString();
        // console.log("D:",date);
        return (
          <Link
            key={`${notification.EmployeeID}-${notification.Date}`}
            to="/AdminTable"
            className="notification-link"
            date={notification.Date}
          >
            Attendance Update {notification.EmployeeID} on {date}
          </Link>
        );
      });
  
      let allNotifications = [
        ...leaveNotifications,
        ...attendanceNotifications,
      ];
  
      // Filter out cleared notifications
      allNotifications = allNotifications.filter(
        notification => !clearedNotifications.includes(notification.key)
      );
  
      setNotifications(allNotifications);
      setNotificationCount(allNotifications.length);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };
  
  const handleNotificationClick = (index) => {
    const selectedNotification = notifications[index];
    setNotifications((prevNotifications) =>
      prevNotifications.filter((_, i) => i !== index)
    );
    setNotificationCount((prevCount) => prevCount - 1);
  
    // Save cleared notification to local storage
    const clearedNotifications = JSON.parse(localStorage.getItem('clearedNotifications')) || [];
    clearedNotifications.push(selectedNotification.key);
    localStorage.setItem('clearedNotifications', JSON.stringify(clearedNotifications));
  
    // Extract criteria from the notification (assuming the notification contains these details)
    const criteria = {
      EmployeeID: selectedNotification.key.split('-')[0],
      StartDate: selectedNotification.props.startDate,
      EndDate: selectedNotification.props.endDate,
      Date: selectedNotification.props.date
    };
  
    // Update highlight criteria
    setHighlightCriteria((prevCriteria) => [...prevCriteria, criteria]);
  
    if (selectedNotification.props.to) {
      navigate(selectedNotification.props.to);
    }
    closeNotifications();
  };

  const handleClearAll = () => {
    setNotifications([]);
    setNotificationCount(0);

    // Save cleared notifications to local storage
    const clearedNotifications = notifications.map(notification => notification.key);
    localStorage.setItem('clearedNotifications', JSON.stringify(clearedNotifications));
  };

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setOpenSidebarToggle(false);
    }
  };

  const handleItemClick = () => {
    setOpenSidebarToggle(false);
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    setShowLogoutConfirmation(true); 
  };

  const handleConfirmLogout = () => {
    setShowLogoutConfirmation(false); 
    Cookies.remove('token');
    navigate('/');
  };

  const handleCloseLogoutModal = () => {
    setShowLogoutConfirmation(false); 
  };

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const closeNotifications = () => {
    setShowNotifications(false);
  };

  const [officeName, setOfficeName] = useState("");

  const fetchOfficeName = async () => {
    try {
      console.log("Fetching office name for username:", username);
      const response = await axios.get(
        `https://api.payroll.functionparks.com/adminDisplay/${username}`
      );
      console.log("Response from backend:", response);
      setOfficeName(response.data.officeName);
    } catch (error) {
      console.error("Error fetching office name:", error);
    }
  };
 
  useEffect(() => {
    if (username) {
      fetchOfficeName();
    }
  }, [username]);

  return (
    <>
      <aside
        id="sidebar"
        ref={sidebarRef}
        className={`${openSidebarToggle ? 'sidebar-responsive' : ''} ${
          !openSidebarToggle ? 'sidebar-closed' : ''
        }`}
      >
        <div className="sidebar-title">
          <div className="sidebar-brand">
            <TbListDetails className="icon_header" /> Admin
          </div>
        </div>

        <ul className="sidebar-list">
          <Link to="/AdminHome" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <MdHome className="icon" /> Dashboard
            </li>
          </Link>

          <Link to="/AdminBasicDetails" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsPeopleFill className="icon" /> Employee
            </li>
          </Link>

          <Link to="/adminTable" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsFillGrid3X3GapFill className="icon" /> Attendance
            </li>
          </Link>

          <Link to="/adminPaid" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsEscape className="icon" /> Leave
            </li>
          </Link>

          <Link to="/adminPaying" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsListCheck className="icon" /> Payroll
            </li>
          </Link>

          <Link to="/adminDayOff" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsEmojiSunglasses className="icon" /> Holidays
            </li>
          </Link>

          <Link to="/adminCompany" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsRepeat className="icon" /> Organization
            </li>
          </Link>

          <Link to="" className="sidebar-link" onClick={(e) => { e.preventDefault(); handleLogout(); }}>
            <li className="sidebar-list-item" onClick={handleItemClick}>
              <MdLogout className="icon" /> LogOut
            </li>
          </Link>
        </ul>
      </aside>

      <header className='header'>
        <div className='menu-icon'>
          <BsJustify className='icon' onClick={OpenSidebar} />
          <div className="office-container">
            {officeName && <span className="office-name">{officeName}</span>}
          </div>
        </div>
        <div className="header-right">
          <div className="notification-wrapper">
            <IoIosNotifications
              className="icon"
              onClick={toggleNotifications}
            />
            {notificationCount > 0 && (
              <span className="notification-count">{notificationCount}</span>
            )}
            {showNotifications && (
              <div className="notification-panel-1">
                <NotificationPopup
                  notifications={notifications}
                  onNotificationClick={handleNotificationClick}
                  onClose={closeNotifications}
                  onClearAll={handleClearAll}
                />
              </div>
            )}
          </div>
        </div>

      </header>
      {showLogoutConfirmation && (
  <div className="modal-backdrop">
    <div className="modal-content">
      <h4 className="modal-title">Confirm Logout</h4>
      <p>Are you sure you want to logout?</p>
      <div className="modal-footer">
        <button onClick={handleCloseLogoutModal} className="btn btn-secondary">
          Cancel
        </button>
        <button onClick={handleConfirmLogout} className="btn btn-primary">
          Logout
        </button>
      </div>
    </div>
  </div>
)}
    </>
  );
}

export default Sidebar;

import React, { useEffect, useState } from "react";
import "./HrHome.css";
import Doughnut from "./HrDoughnut";
import RoundChart from "./HrRoundChart";
import Spend from "./HrSpend";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import Gender from "./HrGender";
import { jwtDecode } from "jwt-decode";
import {useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import Popup from "../../../Popup";


const HrDashboard = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
       if (!token) {
      navigate('/');
    } 
  }, []);
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'HrHome') {
        navigate('/');
      }
    }
  }, []);

  const [totalEmployees, setTotalEmployees] = useState(0);
  const [totalDepartments, setTotalDepartments] = useState(0);
  const [data, setData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [hrName,setHrName] =useState('');

  useEffect(() => {
    const fetchHrName = async () => {
      try {
        const response = await axios.get(`https://api.payroll.functionparks.com/api/officeName/${username}`);
        setHrName(response.data.hrName);
      } catch (error) {
        console.error("Error fetching office name:", error);
      }
    };
 
    fetchHrName();
  }, [username]);

  useEffect(() => {
    const fetchTotalEmployees = async () => {
      try {
        const response = await axios.get(
          `https://api.payroll.functionparks.com/card/Employees/${username}`
        );
        setTotalEmployees(response.data.totalEmployees);
      } catch (error) {
        console.error("Error fetching total employees:", error);
      }
    };

    const fetchTotalDepartments = async () => {
      try {
        const response = await axios.get(
          `https://api.payroll.functionparks.com/api/totalDepartments/${username}`
        );
        setTotalDepartments(response.data.totalDepartments);
      } catch (error) {
        console.error("Error fetching total departments:", error);
      }
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.payroll.functionparks.com/api/employee-count-by-department/${username}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const jsonData = await response.json();
 
        const filteredData = jsonData.filter((item) => item.pv !== "");
 
        setData(filteredData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchTotalEmployees(username);
    fetchTotalDepartments(username);
    fetchData(username);
  }, [username]);

  const [lastPayrollDate, setLastPayrollDate] = useState("");

  useEffect(() => {
    axios.get(`https://api.payroll.functionparks.com/api/last-payroll-date/${username}`)
      .then(response => {
        setLastPayrollDate(response.data.lastPayrollDate);
      })
      .catch(error => {
        console.error("Error fetching last payroll date:", error);
      });
  }, [username]);


  const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props;
    const radius = 10;

    return (
      <>
        <g>
          <circle
            cx={x + width / 2}
            cy={y - radius}
            r={radius}
            fill="#8884d8"
          />
          {value && value.split && value.split(" ").length > 1 && (
            <text
              x={x + width / 2}
              y={y - radius}
              fill="#fff"
              textAnchor="middle"
              dominantBaseline="middle"
            >
              z{value.split(" ")[1]}
            </text>
          )}
        </g>
      </>
    );
  };

  useEffect(() => {
    const loginTime = sessionStorage.getItem('loginTime');
    if (loginTime) {
      setShowPopup(true);
      sessionStorage.removeItem('loginTime');
    }
  }, []);

  return (
    <div>
      {showPopup && (
        <Popup message={`Welcome back HR: ${hrName}`} onClose={() => setShowPopup(false)} />
      )}
      <div className="hr-main--contain">
        {/* <h2>HR DASHBOARD</h2> */}
        <div className="hr-card--container">
          <div className="hr-card">
            <div className="hr-card--name">{totalEmployees}</div>
            <div className="hr-card--title">
              <h2>Total Employees</h2>
            </div>
          </div>
          <div className="hr-card">
            <div className="hr-card--name">{totalDepartments}</div>
            <div className="hr-card--title">
              <h2>Total Departments</h2>
            </div>
          </div>
          <div className="hr-card">
            <div className="hr-card--name">{lastPayrollDate}</div>
            <div className="hr-card--title">
              <h2>Last Payroll</h2>
            </div>
          </div>
        </div>
        <div className="hr-chart">
          <div className="hr-chart_1">
            <h4 className="text-primary shadow-sm p-2 mb-4 bg-body rounded">
              Employee Count in Department
            </h4>
            <ResponsiveContainer width="100%" height="95%">
              <BarChart
                width={100}
                height={300}
                data={data}
                margin={{
                  top: 0,
                  right: 30,
                  left: 18,
                  bottom: 80,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="pv" />
                <YAxis />
                {/* <YAxis interval={10} tickCount={11} domain={[0, 10]} /> */}

                <Tooltip />
                {/* <Legend /> */}
                <Bar dataKey="EmployeeCount" fill="#8884d8" minPointSize={5}>
                  <LabelList
                    dataKey="EmployeeCount"
                    content={renderCustomizedLabel}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          <>
            <div className="hr-chart_2">
              <Doughnut />
            </div>
          </>
        </div>
        <div className="hr-box">
          <div className="hr-chart_4">
            <Spend />
          </div>
          <div className="hr-chart_4">
            <Gender/>
          </div>
          <div className="hr-chart_3">
            <RoundChart />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HrDashboard;

import React, { useEffect, useState } from 'react';
import HrSidebar from "./HrSidebar";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import moment from 'moment';
import  Axios  from 'axios';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import './HrHome.css'

const HrHolidays = () => {

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
       if (!token) {
      navigate('/');
    } 
  }, []);
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'HrHome') {
        navigate('/');
      }
    }
  }, []);

  const fetchData = (() => {
    Axios.get(`https://api.payroll.functionparks.com/HR/holiday/get/${username}`).then ((response) => {
      setData(response.data);
    })
    .catch((error) => {
      console.error("Error fetching Assets data:", error);
    });
  })
  useEffect(() => {
    if(username){
      fetchData();
    }
  }, [username]);

  const [addHolidayOpen, setAddHolidayOpen] = useState(false); 
  const [newHoliday, setNewHoliday] = useState('');
  const [newdate, setNewDate] = useState('');
  const [newday, setNewDay] = useState('');
  const [newlocation, setNewLocation] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedRow, setEditedRow] = useState({});
  const [data, setData] = useState([ ]);
  const [selectedMonth, setSelectedMonth] = useState(""); 

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  }

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  let filteredData = [...data];

  if (searchQuery) {
    filteredData = filteredData.filter(
      (item) => item.HolidayName.toLowerCase() === searchQuery.toLowerCase()
    );
  }

  if (selectedMonth) {
    const [year, month] = selectedMonth.split("-");
    filteredData = filteredData.filter(
      item => moment(item.Date).format("YYYY") === year &&
              moment(item.Date).format("MM") === month
    );
  }

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);

  const getDayFromDate = (dateString) => {
    const date = new Date(dateString);
    const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
    setNewDay(dayOfWeek);
  };

  const handleOpenAddHolidayDialog = () => {
    setAddHolidayOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
  };

  const handleCloseAddHolidayDialog = () => {
    setAddHolidayOpen(false);
  };
  const handleAddHoliday = (event) => {
  if(!newHoliday || !newdate || !newlocation ) {
    toast.warning('Please fill in all mandatory fields');
    return;
  }
    event.preventDefault();
    console.log("Adding Holiday....", {
      HolidayName: newHoliday,
      Date: newdate,
      Day: newday,
      Location: newlocation,
      Description: newDescription,
      officeId: username,
    });
    Axios.post("https://api.payroll.functionparks.com/HR/holiday/post",{
      HolidayName: newHoliday,
      Date: newdate,
      Day: newday,
      Location: newlocation,
      Description: newDescription,
      officeId: username,
     }).then((response) => {
      console.log("Holiday added Successfully:", response.data);
      const newHolidaytype = {
        HolidayName: newHoliday,
        Date: newdate,
        Day: newday,
        Location: newlocation,
        Description: newDescription,
        officeId: username,
      };
    setData((preData) => [...data, newHolidaytype]);
    setNewHoliday('');
    setNewDate('');
    setNewDay('');
    setNewLocation('');
    setNewDescription('');
    fetchData();
    handleCloseAddHolidayDialog();
    toast.success("Holiday added successfully");
  })
  .catch((error) => {
    console.error("Error adding new Holiday:", error);
    toast.error("Failed to add Holiday");
  });
};

const handleFormSubmit = (event) => {
  event.preventDefault();
  console.log("Form submitted:", editedRow);
  Axios.put(`https://api.payroll.functionparks.com/holidays/put/${editedRow.id}`, editedRow)
    .then(response => {
      fetchData();
      handleClose();
      handleCloseEditDialog();
      toast.success('Record updated successfully');
    })
    .catch(error => {
      console.error('Error updating record:', error);
      toast.error('Failed to update record');
    });
  };


  const handleEditFormChange = (event, field) => {
    const { value } = event.target;
    setEditedRow((prev) => {
      if (field === "Date") {
        const dayOfWeek = new Date(value).toLocaleDateString('en-US', { weekday: 'long' });
        return {
          ...prev,
          [field]: value,
          Day: dayOfWeek,
        };
      } else {
        return {
          ...prev,
          [field]: value,
        };
      }
    });
  };
  

const handleEditOpen = (row) => {
  setEditedRow({
    ...row,
    Date: moment(row.Date).format("YYYY-MM-DD"),
  });
  setEditDialogOpen(true);
};


const handleDeleteConfirmed = () => {
  if (selectedRow) {
    const holidayNameToDelete = selectedRow.HolidayName; 
    Axios.delete(`https://api.payroll.functionparks.com/holidays/delete/${holidayNameToDelete}`)
      .then(response => {
        const updatedData = data.filter((item) => item.HolidayName !== holidayNameToDelete);
        setData(updatedData);
        setDeleteConfirmationOpen(false);
        handleClose();
        toast.success('Record deleted successfully');
      })
      .catch(error => {
        console.error('Error deleting record:', error);
        toast.error('Failed to delete record');
      });
  }
};

const handleDeleteCancelled = () => {
  setDeleteConfirmationOpen(false);
};
const handleDeleteConfirmation = () => {
  setDeleteConfirmationOpen(true);
};

  return (
    <>
    <HrSidebar/>
      <>
      <div className='main-container'>
          <h1 className='header1'>Check Holidays</h1>
        </div>
      <div style={{padding:"15px"}}>
        
        <div style={{display:"flex", justifyContent:"space-between", marginBottom:"15px"}} className='dot'>

          <div className='diff'>
          <TextField
            variant='outlined'
            label="Month"
            type="month"
            value={selectedMonth}
            onChange={handleMonthChange}
            style={{ marginLeft: '10px' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
            <TextField 
            variant="outlined"
              label="Search"
              value={searchQuery}
              onChange={handleSearchChange}
              className="super-text"
              style={{marginLeft: '10px'}}/>
          </div>

          <div className="gggg" style={{marginRight:"15px", marginTop:"10px"}}>
          <Button variant="contained" color="primary" size="small" onClick={handleOpenAddHolidayDialog}>
                + Add Holiday Type
          </Button>
          <Dialog open={addHolidayOpen} onClose={handleCloseAddHolidayDialog}>
          <DialogTitle>Add New Asset</DialogTitle>
            <DialogContent>

              <TextField
                label="Name Of Holiday"
                value={newHoliday}
                onChange={(e) => setNewHoliday(e.target.value)}
                fullWidth
                margin="normal"
              />
              
                <TextField
            // label="Date"
            value={newdate}
            onChange={(e) => {
              setNewDate(e.target.value);
              getDayFromDate(e.target.value); 
            }}
            fullWidth
            margin="normal"
            type="date"
          />

          <TextField
            label="Day"
            value={newday}
            onChange={(e) => setNewDay(e.target.value)}
            fullWidth
            margin="normal"
            disabled
          />
              <TextField
                label="Location"
                value={newlocation}
                onChange={(e) => setNewLocation(e.target.value)}
                fullWidth
                margin="normal"
              />
             
              <TextField
                label="Description"
                value={newDescription}
                onChange={(e) => setNewDescription(e.target.value)}
                fullWidth
                margin="normal"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAddHolidayDialog} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleAddHoliday} color="primary">
                Add
              </Button>
            </DialogActions>
          </Dialog>
          </div>

        </div>

        <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead style={{ backgroundColor: 'rgb(167, 165, 165)' }}>
            <TableRow>
              
              <TableCell className="super-stickyCell">Holiday Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Day</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData.map((row, index) => (
              <TableRow key={index}>
                <TableCell className="super-stickyCell">{row.HolidayName}</TableCell>
                <TableCell>{moment(row.Date).format(
                        "DD-MM-YYYY")}</TableCell>
                <TableCell>{row.Day}</TableCell>
                <TableCell>{row.Location}</TableCell>
                <TableCell>{row.Description ? row.Description : 'None'}</TableCell>
                <TableCell>
                  <Button onClick={(event) => handleClick(event, row)}>
                    <MoreHorizIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
        <div>
          <Button disabled={currentPage === 1} onClick={handlePrevPage}><FaChevronLeft /></Button>
          <span style={{ margin: '0 10px' }}>Page {currentPage} of {totalPages}</span>
          <Button disabled={currentPage === totalPages} onClick={handleNextPage}><FaChevronRight /></Button>
        </div>
        <Typography variant="body2">
          {' '}
          <select value={rowsPerPage} onChange={handleRowsPerPageChange} style={{ width: '40px', height: '28px' }}>
            <option value={4}>4</option>
            <option value={8}>8</option>
            <option value={12}>12</option>
          </select>
        </Typography>
      </div>

      {selectedRow && (
  <Menu
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={handleClose}
  >
    <MenuItem onClick={() => handleEditOpen(selectedRow)}>Edit</MenuItem> 
    <MenuItem onClick={handleDeleteConfirmation}>Delete</MenuItem>
  </Menu>
)}

<Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
  <DialogTitle>Edit Details</DialogTitle>
  <DialogContent>
    <form onSubmit={handleFormSubmit}>
      <TextField
        id="HolidayName"
        label="Holiday Name:"
        value={editedRow ? editedRow.HolidayName : ""}
        onChange={(e) => handleEditFormChange(e, "HolidayName")}
        fullWidth
        margin="normal"
      />
  <TextField
  id="Date"
  label="Date"
  type="date"
  value={editedRow.Date || ""}
  onChange={(e) => handleEditFormChange(e, "Date")}
  fullWidth
  margin="normal"
/>

      <TextField
        id="Day"
        label="Day"
        value={editedRow ? editedRow.Day : ""}
        onChange={(e) => handleEditFormChange(e, "Day")}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        id="Location"
        label="Location:"
        value={editedRow ? editedRow.Location : ""}
        onChange={(e) => handleEditFormChange(e, "Location")}
        fullWidth
        margin="normal"
      />
      <TextField
        id="Description"
        label="Description:"
        value={editedRow ? editedRow.Description : ""}
        onChange={(e) => handleEditFormChange(e, "Description")}
        fullWidth
        margin="normal"
      />
    </form>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setEditDialogOpen(false)} color="secondary">
      Cancel
    </Button>
    <Button onClick={handleFormSubmit} color="primary">
      Update
    </Button>
  </DialogActions>
</Dialog>


<Dialog open={deleteConfirmationOpen} onClose={handleDeleteCancelled}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this record?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancelled} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmed} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      </div>
      </>
      <ToastContainer />
    </>
  )
}

export default HrHolidays
import React, { useEffect, useState } from 'react';
import EmpSidebar from './EmpSidebar';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import Axios from 'axios';
import styles from './PayHeader.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PayHeader = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [user, setUser] = useState({});
  const [message, setMessage] = useState('');
  const [activeCard, setActiveCard] = useState(null);

  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'EmployeeDashboard') {
        navigate('/');
      }
    }
  }, [navigate]);

  useEffect(() => {
    const fetchLatestUserData = async () => {
      try {
        const response = await Axios.get(`https://api.payroll.functionparks.com/loginmypay/latest/${username}`);
        if (response.data.user) {
          setUser(response.data.user);
          setSelectedMonth(new Date(response.data.user.Date).getMonth() + 1);
          setSelectedYear(new Date(response.data.user.Date).getFullYear());
          setMessage('');
        } else {
          setUser({});
          toast.warning('No details found.');
        }
      } catch (error) {
        console.error("Error fetching latest user data", error);
        toast.warning('Error fetching details. Please try again later.');
      }
    };

    if (username) {
      fetchLatestUserData();
    }
  }, [username]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await Axios.get(`https://api.payroll.functionparks.com/loginmypay/${username}`, {
          params: {
            month: selectedMonth,
            year: selectedYear,
          },
        });

        if (response.data.user) {
          setUser(response.data.user);
          setMessage('');
        } else {
          setUser({});
          toast.warning('No details found for this month.');
        }
      } catch (error) {
        console.error("Error fetching details for selected month and year", error);
        toast.warning('Error fetching details. Please try again later.');
      }
    };

    if (username && selectedMonth && selectedYear) {
      fetchUserData();
    }
  }, [username, selectedMonth, selectedYear]);

  const handleCardClick = (cardName) => {
    setActiveCard(cardName);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  return (
    <>
      <EmpSidebar />
      <div className={styles.container}>
        <div className={styles.filters}>
          <select value={selectedMonth} onChange={handleMonthChange} className={styles.select}>
            <option value="">Select Month</option>
            <option value="01">January</option>
            <option value="02">February</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">August</option>
            <option value="09">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
          <select value={selectedYear} onChange={handleYearChange} className={styles.select}>
            <option value="">Select Year</option>
            <option value="2034">2034</option>
            <option value="2033">2033</option>
            <option value="2032">2032</option>
            <option value="2031">2031</option>
            <option value="2030">2030</option>
            <option value="2029">2029</option>
            <option value="2028">2028</option>
            <option value="2027">2027</option>
            <option value="2026">2026</option>
            <option value="2025">2025</option>
            <option value="2024">2024</option>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
            <option value="2021">2021</option>
          </select>
        </div>

        {message && <p>{message}</p>}

        <div
          className={`${styles.card} ${activeCard === 'currentCompensation' ? styles.active : ''}`}
          onClick={() => handleCardClick('currentCompensation')}
        >
          <div className={styles.cardContent}>
            <div>
              <p>CURRENT COMPENSATION</p>
              <h4>{user.totalSalary ? `INR ${user.totalSalary} / Annum` : 'N/A'}</h4>
            </div>
          </div>
        </div>

        <div
          className={`${styles.card} ${activeCard === 'paycycle' ? styles.active : ''}`}
          onClick={() => handleCardClick('paycycle')}
        >
          <div className={styles.cardContent}>
            <div>
              <p>PAY CYCLE</p>
              <h4>Monthly</h4>
            </div>
          </div>
        </div>

        <div
          className={`${styles.card} ${activeCard === 'payroll' ? styles.active : ''}`}
          onClick={() => handleCardClick('payroll')}
        >
          <div className={styles.cardContent}>
            <div>
              <h3>Payroll</h3>
              <p>Generated Month: {user.Date ? new Date(user.Date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' }) : 'N/A'}</p>
              <h4>{user.MonthlySalary ? `INR ${user.MonthlySalary} /Month` : 'N/A'}</h4>
            </div>
          </div>
        </div>

        <div
          className={`${styles.card} ${activeCard === 'components' ? styles.active : ''}`}
          onClick={() => handleCardClick('components')}
        >
          <div className={styles.cardContent}>
            <div className={styles.row}>
              <p>BASIC SALARY :</p>
              <h4>{user.basic ? `INR ${user.basic}` : 'N/A'}</h4>
            </div>
            <div className={styles.row}>
              <p>BONUS :</p>
              <h4>{user.Bonus ? `INR ${user.Bonus}` : 'N/A'}</h4>
            </div>
            <div className={styles.row}>
              <p>GROSS COMPONENTS :</p>
              <div>
                {user.GrossComponents
                  ? user.GrossComponents.split(',').map((component, index) => (
                      <p key={index} className={styles.component}>{component}</p>
                    ))
                  : 'N/A'}
              </div>
            </div>
            <div className={styles.row}>
              <p>DEDUCTION COMPONENTS :</p>
              <div>
                {user.DeductionComponents
                  ? user.DeductionComponents.split(',').map((component, index) => (
                      <p key={index} className={styles.component}>{component}</p>
                    ))
                  : 'N/A'}
              </div>
            </div>
            <div className={styles.row}>
              <p>Month :</p>
              <h4>{user.MonthlySalary ? `INR ${user.Monthly} /Month` : 'N/A'}</h4>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </>
  );
};

export default PayHeader;

import React, { useState, useEffect } from 'react';

const Mouse = () => {
  
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const [employeeID, setEmployeeID] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [attendanceCount, setAttendanceCount] = useState(null);
  const [attendancePercentage, setAttendancePercentage] = useState(null);
  const [weekOffs, setWeekOffs] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [leavesCount, setLeavesCount] = useState(null);
  const [allowableLeaves, setAllowableLeaves] = useState([]);
  const [leaves, setLeaves] = useState([]);
  const [weekOffDaysCount, setWeekOffDaysCount] = useState(null);
  const [overLimitLeaves, setOverLimitLeaves] = useState([]);
  const [totalDays, setTotalDays] = useState(null);
  const [number, setNumber] = useState(null);
  const [workingDays, setWorkingDays] = useState(null);

  useEffect(() => {
    if (employeeID && selectedMonth) {
      fetchAttendanceCount();
    }
  }, [employeeID, selectedMonth]);

  const fetchAttendanceCount = async () => {
    try {
      const response = await fetch(`https://api.payroll.functionparks.com/api/get-attendance-count?employeeID=${employeeID}&month=${selectedMonth}`);
      const data = await response.json();
      setAttendanceCount(data.attendanceCount);
      setAttendancePercentage(data.attendancePercentage);
      setWeekOffs(data.weeklyOffs || []);
      setHolidays(data.holidays || []);
      setLeavesCount(data.leavesCount);
      setAllowableLeaves(data.allowableLeaves || []);
      setLeaves(data.leaves || []);
      setWeekOffDaysCount(data.weekOffDaysCount);
      setOverLimitLeaves(data.overLimitLeaves || []);
      setTotalDays(data.totalDays);
      setWorkingDays(data.workingDays);
      setNumber(data.number);
    } catch (error) {
      console.error('Error fetching attendance count:', error);
    }
  };

  return (
    <div style={{ width: "200px", marginTop:"100px", marginLeft:"200px", justifyContent: "center", display: "flex", flexDirection: "column", gap: "10px" }}>
            <input
        type='text'
        value={employeeID}
        onChange={(e) => setEmployeeID(e.target.value)}
        placeholder="Enter Employee ID"
      />
      <select value={selectedMonth} onChange={(e) => setSelectedMonth(parseInt(e.target.value))}>
        {months.map((month, index) => (
          <option key={index} value={index + 1}>
            {month}
          </option>
        ))}
      </select>
      <button onClick={fetchAttendanceCount}>Generate</button>
      {attendanceCount !== null && (
        <div>
          <div>Attendance Percentage: {attendancePercentage}%</div>
          {weekOffDaysCount !== null && (
            <div>{weekOffDaysCount === 0 ? "No week offs for the selected month" : `Week Offs Count: ${weekOffDaysCount}`}</div>
          )}
          <div>Total Days in Month: {totalDays}</div>
          <div>Working Days: {workingDays}</div>
          <div>Present Days: {number}</div>
          <div>Weekly Offs:</div>
          <ul>
            {weekOffs.map((weekOff, index) => (
              <li key={index}>{weekOff}</li>
            ))}
          </ul>
          <div>Holidays:</div>
          <ul>
            {holidays.map((holiday, index) => (
              <li key={index}>
                {holiday.name} ({new Date(holiday.date).toLocaleDateString()})
              </li>
            ))}
          </ul>
          <div>Leaves:</div>
          <ul>
            {leaves.map((leave, index) => (
              <li key={index}>
                {leave.LeaveType}: {new Date(leave.StartDate).toLocaleDateString()} - {new Date(leave.EndDate).toLocaleDateString()}
              </li>
            ))}
          </ul>
          <div>Allowable Leaves:</div>
          <ul>
            {allowableLeaves.map((leave, index) => (
              <li key={index}>
                {leave.LeaveType}: {leave.PerMonth} per month
              </li>
            ))}
          </ul>
          <div>Over Limit Leaves:</div>
          <ul>
            {overLimitLeaves.map((leave, index) => (
              <li key={index}>
                {leave.LeaveType}: {leave.OverLimitDays} days over limit
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Mouse;


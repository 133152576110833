import React, { useState, useEffect } from 'react';
import AdminSidebar from './AdminSidebar';
import AdminPayroll from './AdminPayroll';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Checkbox, Menu, MenuItem, Table, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TextField, Button } from '@mui/material';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Axios from 'axios';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import './AdminPayroll.css'

const AllPayslips = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(moment().format('YYYY-MM')); 

  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'AdminHome') {
        navigate('/');
      }
    }
  }, [navigate]);

  useEffect(() => {
    fetchData();
  }, [username, selectedMonth]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const fetchData = () => {
    const year = moment(selectedMonth).year();
    const month = moment(selectedMonth).month() + 1; 

    Axios.get(`https://api.payroll.functionparks.com/api/Employee/slip/${username}?year=${year}&month=${month}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    let results = data;

    if (searchQuery) {
      results = results.filter(row =>
        row.EmployeeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.EmployeeID.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (selectedMonth) {
      const [year, month] = selectedMonth.split("-");
      results = results.filter(
        item => moment(item.Date).format("YYYY") === year &&
                moment(item.Date).format("MM") === month
      );
    }

    setFilteredData(results);
  }, [searchQuery, selectedMonth, data]);


  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleCheckboxChange = (event, row) => {
    const isChecked = event.target.checked;
    setSelectedRows(prevSelectedRows => {
      if (isChecked) {
        return [...prevSelectedRows, row];
      } else {
        return prevSelectedRows.filter(selectedRow => selectedRow.EmployeeID !== row.EmployeeID);
      }
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows([...filteredData]);
    } else {
      setSelectedRows([]);
    }
  };

  const handleView = () => {
    selectedRows.forEach(selectedRow => {
      Axios.get(`https://api.payroll.functionparks.com/api/Employee/slip/${selectedRow.EmployeeID}/${selectedRow.FileName}`, { responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', selectedRow.FileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((error) => {
          console.error(`Error downloading file for ${selectedRow.EmployeeID}:`, error);
        });
    });
  
    setAnchorEl(null);
  };
  
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRows([row]);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRows([]);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format('MMMM YYYY');
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <>
      <AdminSidebar />
      <AdminPayroll />
      <div style={{padding:"15px"}}>
        <div className='don' >
          <TextField
            variant='outlined'
            label="Name & ID"
            value={searchQuery}
            onChange={handleSearchChange}
            style={{ marginLeft: '10px' }}
          />
          <div className='month-search' >
          <TextField
            variant='outlined'
            label="Month"
            type="month"
            value={selectedMonth}
            onChange={handleMonthChange}
            style={{ marginRight: '20px' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          </div>
          
        </div>
        <TableContainer component={Paper}>
          <Table aria-label='simple table'>
            <TableHead style={{ backgroundColor: "rgb(167, 165, 165)" }}>
              <TableRow>
                {/* <TableCell>
                  <Checkbox
                    checked={selectedRows.length === filteredData.length}
                    onChange={handleSelectAll}
                    indeterminate={selectedRows.length > 0 && selectedRows.length < filteredData.length}
                  />
                </TableCell> */}
                <TableCell className="super-stickyCell">Employee ID</TableCell>
                <TableCell>Employee Name</TableCell>
                <TableCell>PaySlip File</TableCell>
                <TableCell>Bonus</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.map((row, index) => (
                <TableRow key={index}>
                  {/* <TableCell>
                    <Checkbox
                      checked={selectedRows.some(selectedRow => selectedRow.EmployeeID === row.EmployeeID)}
                      onChange={(event) => handleCheckboxChange(event, row)}
                    />
                  </TableCell> */}
                  <TableCell className="super-stickyCell">{row.EmployeeID}</TableCell>
                  <TableCell>{row.EmployeeName}</TableCell>
                  <TableCell>{row.FileName}</TableCell>
                  <TableCell>{row.Bonus}</TableCell>
                  <TableCell>{formatDate(row.Date)}</TableCell>
                  <TableCell>
                    <Button onClick={(event) => handleClick(event, row)}>
                      <MoreHorizIcon />
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => handleView(row)}>View</MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
          <div>
            <Button disabled={currentPage === 1} onClick={handlePrevPage}><FaChevronLeft /></Button>
            <span style={{ margin: '0 10px' }}>Page {currentPage} of {Math.ceil(filteredData.length / rowsPerPage)}</span>
            <Button disabled={currentPage === Math.ceil(filteredData.length / rowsPerPage)} onClick={handleNextPage}><FaChevronRight /></Button>
          </div>
          <Typography variant="body2">
            {' '}
            <select value={rowsPerPage} onChange={handleRowsPerPageChange} style={{ width: '40px', height: '28px' }}>
              <option value={4}>4</option>
              <option value={8}>8</option>
              <option value={12}>12</option>
            </select>
          </Typography>
        </div>
      </div>
    </>
  );
}

export default AllPayslips;

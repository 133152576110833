import React, { useEffect, useState } from "react";
import EmpSidebar from "./EmpSidebar";
import "./EmpProfile.css";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

const EmpProfile = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "EmployeeDashboard") {
        navigate("/");
      }
    }
  }, []);

  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [type, setType] = useState([]);
  const [username, setUsername] = useState("");

  const fetchData = (() => {
    axios
      .get(`https://api.payroll.functionparks.com/employee/from`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Skills data:", error);
      });
  })
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (username) {
      const fetchnameData = async () => {
        try {
          const response = await axios.get(
            `https://api.payroll.functionparks.com/profile/${username}`
          );
          const loggedInUser = response.data.user;
          setUser(loggedInUser);
        } catch (error) {
          console.error("Error fetching user data", error);
        }
      };
      fetchnameData();
    }
  }, [username]);

  const fetchnameData = async () => {
    try {
      const response = await axios.get(
        `https://api.payroll.functionparks.com/Skills/${username}`
      );
      const loggedInUser = response.data.user;
      setType(loggedInUser);
    } catch (error) {
      console.error("Error fetching user data", error);
    }
  };

  useEffect(() => {
    if (username) {
      fetchnameData();
    }
  }, [username]);

  const [showOtherDetails, setShowOtherDetails] = useState(false);

  const handleOtherButtonClick = () => {
    setShowOtherDetails(!showOtherDetails);
  };

  const calculateDuration = () => {
    const joiningDate = moment(user.joiningDate);
    const currentDate = moment();
    const months = currentDate.diff(joiningDate, "months");
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
    return `${years} years and ${remainingMonths} months`;
  };

  const [addOpen, setAddAOpen] = useState(false);
  const [about, setAbout] = useState("");
  const [interests, setInterests] = useState("");
  const [skills, setSkills] = useState("");
  const [hobbies, setHobbies] = useState("");

  const handleOpenDialog = () => {
    setAddAOpen(true);
  };

  const handleCloseDialog = () => {
    setAddAOpen(false);
  };

  const handleAddDetails = () => {
    const newDetails = {
      EmployeeID: user.EmployeeID, 
      About: about,
      Interests: interests,
      Skills: skills,
      Hobbies: hobbies,
      officeId: username,
    };

    axios.post('https://api.payroll.functionparks.com/updateDetails', newDetails)
      .then((response) => {
        console.log(response.data);
        setAbout("");
        setInterests("");
        setSkills("");
        setHobbies("");
        toast.success("Successfully updated");
        fetchnameData();
        handleCloseDialog();
      })
      .catch((error) => {
        console.error('There was an error!', error);
        toast.error("Error updating details");
      });
  };

  return (
    <>
      <EmpSidebar />
      <div className="emp-profile-container">
        <h3 style={{ color: "blue", fontWeight: "bold", fontFamily:"serif" }}>
          Visit your corporate profile
        </h3>
        <div className="emp-profile-card">
          <div className="emp-profile-info">
            <h3 className="email-text">{user.DisplayName}</h3>
            <p>
              <i className="fas fa-map-marker-alt"></i> {user.city}
            </p>
            <p>
              <span className="email-icon">
                <i className="fas fa-envelope"></i>
              </span>
              <span className="email-text">{user.Email}</span>
            </p>
            <p>
              <i className="fas fa-mobile-alt"></i> {user.PhoneNumber}
            </p>
            <p>
              <strong>Joining Date:</strong>{" "}
              {moment(user.joiningDate).format("DD-MM-YYYY")}
            </p>
            
          </div>

          <div className="emp-profile-details">
            <div className="emp-profile-section">
              <h3>Job Details</h3>
              <p>
                <strong>Job Title:</strong>
                {user.JobTitle}
              </p>
              <p>
                <strong>Department:</strong> {user.JobDepartment}
              </p>
              <p>
                <strong>Reporting to:</strong> {user.Repoting_to}
              </p>
            <p>
              <strong>Experience:</strong> 
              <span style={{color:"blueviolet"}}>{calculateDuration()}</span>
              
            </p>
            </div>
            <div className="emp-profile-buttons">
              <button className="profile-btn" onClick={handleOtherButtonClick}>
                Other Details
              </button>
              {/* <button className="job-btn">Edit</button>
              <button className="assets-btn">Assets</button> */}
            </div>
          </div>
        </div>

        <div className="emp-profile-card">
        <div className="emp-profile-section">
            <h3>About</h3>
            <p>
              {type?.About || ""}
            </p>
          </div>
          <div className="emp-profile-section">
            <h3>Interests</h3>
            <p>{type?.Interests || ""}</p>
          </div>
          <div className="emp-profile-section">
            <h3>Skills</h3>
            <p>{type?.Skills || ""}</p>
          </div>
          <div className="emp-profile-section">
            <h3>Hobbies</h3>
            <p>{type?.Hobbies || ""}</p>
          </div>
          <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleOpenDialog}
              className="edit-btns">
              Update
            </Button>
            
            <Dialog open={addOpen} onClose={handleCloseDialog}>
              <DialogTitle>Update details</DialogTitle>
              <DialogContent>
                <TextField
                  label="About"
                  value={about}
                  onChange={(e) => setAbout(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Interests"
                  value={interests}
                  onChange={(e) => setInterests(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Skills"
                  value={skills}
                  onChange={(e) => setSkills(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Hobbies"
                  value={hobbies}
                  onChange={(e) => setHobbies(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="secondary">
                  Cancel
                </Button>
                <Button onClick={handleAddDetails} color="primary">
                  Update
                </Button>
              </DialogActions>
            </Dialog>
        </div>
        
        {showOtherDetails && (
          <div className="emp-profile-card">
            <div className="emp-profile-section">
              <h3>Other Details</h3>
              <p>
                <strong>Weekly Offs:</strong> {user.WeeklyOffs}
              </p>
              <p>
                <strong>Shifts:</strong> {user.Shifts}
              </p>
              <p>
                <strong>Notice Period:</strong> {user.NoticePeriod}
              </p>
              <p>
                <strong>Leaves:</strong> 12 per year
              </p>
            </div>
          </div>
        )}
      </div>
      <ToastContainer/>
    </>
  );
};

export default EmpProfile;


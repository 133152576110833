import React, { useEffect, useState } from "react";
import AdminPayroll from './AdminPayroll';
import AdminSidebar from './AdminSidebar';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Button,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody, 
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox
} from "@mui/material";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import "./AdminPayroll.css"


const PayrollData = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'AdminHome') {
        navigate('/');
      }
    }
  }, [navigate]);

  const [currentDate, setCurrentDate] = useState(moment());
  const [searchQuery, setSearchQuery] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [employeesWithExistingPayslips, setEmployeesWithExistingPayslips] = useState([]);
  const [newEmployee, setNewEmployee] = useState({
    EmployeeID: "",
    DisplayName: "",
    Bonus: ""
  });

  const fetchData =  (() => {
    Axios.get(`https://api.payroll.functionparks.com/api/all/Employee/details/${username}`).then((response) => {
      setData(response.data);
    }).catch((error) => {
      console.error("Error fetching data:", error);
    });
    setCurrentDate(moment());
  });

  useEffect(() => {
    fetchData();
  }, [username]);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRows([row]);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRows([]);
  };

  const handleAddOpen = () => {
    if (selectedRows.length > 0) {
      setNewEmployee({
        EmployeeID: selectedRows[0].EmployeeID,
        DisplayName: selectedRows[0].DisplayName,
        Bonus: ""
      });
    }
    setOpenAddDialog(true);
  };

  const handleAddClose = () => {
    setOpenAddDialog(false);
    setNewEmployee({ EmployeeID: "", DisplayName: "", Bonus: "" });
  };

  const handleAddChange = (event) => {
    const { id, value } = event.target;
    setNewEmployee((prevNewEmployee) => ({
      ...prevNewEmployee,
      [id]: value,
    }));
  };

  const handleAddSubmit = () => {
    console.log("Submitting new bonus data:", newEmployee);
  
    Axios.post("https://api.payroll.functionparks.com/api/addBonus", newEmployee)
      .then((response) => {
        handleClose();
        console.log("Server response:", response.data);
  
        setData((prevData) =>
          prevData.map((employee) => {
            if (employee.EmployeeID === newEmployee.EmployeeID) {
              const updatedEmployee = {
                ...employee,
                Bonus: newEmployee.Bonus
              };
              console.log("Updated employee data:", updatedEmployee);
              return updatedEmployee;
            }
            return employee;
          })
        );
        fetchData();
        handleAddClose();
      })
      .catch((error) => {
        console.error("Error updating bonus:", error);
      });
  };
  

  const handleCheckboxChange = (event, row) => {
    const isChecked = event.target.checked;
    setSelectedRows(prevSelectedRows => {
      if (isChecked) {
        return [...prevSelectedRows, row];
      } else {
        return prevSelectedRows.filter(selectedRow => selectedRow.EmployeeID !== row.EmployeeID);
      }
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(data);
    } else {
      setSelectedRows([]);
    }
  };
  
  const handleResetBonuses = () => {
    Axios.post("https://api.payroll.functionparks.com/api/resetBonuses")
      .then((response) => {
        console.log("Bonuses reset:", response.data);
        setData((prevData) =>
          prevData.map((employee) => ({
            ...employee,
            Bonus: 0
          }))
        );
      })
      .catch((error) => {
        console.error("Error resetting bonuses:", error);
      });
  };

  let filteredData = [...data];

  if (searchQuery) {
    filteredData = filteredData.filter((item) =>
      item.DisplayName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.EmployeeID.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);

  
  const handleGeneratePDF = () => {
    const rowsToPrint = selectedRows.length > 0 ? selectedRows : [];
    if (rowsToPrint.length === 0) {
      toast.error("Please select a row to generate PDF.");
      return;
    }

    const currentDateFormatted = currentDate.format('YYYY-MM');
    const employeeIds = rowsToPrint.map(row => row.EmployeeID);

    Axios.post("https://api.payroll.functionparks.com/api/checkPayslips", { currentDate: currentDateFormatted, employeeIds })
      .then(response => {
        const existingPayslips = response.data;
        if (existingPayslips.length > 0) {
          setEmployeesWithExistingPayslips(existingPayslips.map(employee => employee.EmployeeID));
          setConfirmDialogOpen(true);
        } else {
          generateNewPayslips(rowsToPrint);
        }
      })
      .catch(error => {
        console.error('Error checking existing payslips:', error);
        toast.error("Failed to check existing payslips.");
      });
  };

  // const generateNewPayslips = (rowsToPrint) => {
  //   rowsToPrint.forEach(row => {
  //     const employeeId = row.EmployeeID;
  //     Axios.get(`https://api.payroll.functionparks.com/generate-payslip/${employeeId}`, { responseType: 'blob' })
  //       .then(response => {
  //         const url = window.URL.createObjectURL(new Blob([response.data]));
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.setAttribute('download', `PaySlip_${employeeId}.pdf`);
  //         document.body.appendChild(link);
  //         link.click();
  //         toast.success(`PDF generated successfully for Employee ID: ${employeeId}`);
  //       })
  //       .catch(error => {
  //         console.error('Error generating PDF:', error);
  //         toast.error("Failed to generate PDF.");
  //       });
  //   });
  // };

  const generateNewPayslips = (rowsToPrint) => {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1; 
    const year = currentDate.getFullYear();
  
    rowsToPrint.forEach(row => {
      const employeeId = row.EmployeeID;
      Axios.get(`https://api.payroll.functionparks.com/generate-payslip/${employeeId}`, {
        params: {
          month: month.toString().padStart(2, '0'), // format month as MM
          year: year.toString(),
          officeId: username,
        },
        responseType: 'blob'
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `PaySlip_${employeeId}_${month}_${year}.pdf`);
          document.body.appendChild(link);
          link.click();
          toast.success(`PDF generated successfully for Employee ID: ${employeeId}`);
        })
        .catch(error => {
          console.error('Error generating PDF:', error);
          toast.error("Failed to generate PDF.");
        });
    });
  };
  
  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };
  
  const handleConfirmGeneratePDF = () => {
    setConfirmDialogOpen(false);
    const rowsToPrint = selectedRows.length > 0 ? selectedRows : [];
    if (rowsToPrint.length === 0) {
      toast.error("Please select a row to generate PDF.");
      return;
    }

    const currentDateFormatted = currentDate.format('YYYY-MM');
    const employeeIds = rowsToPrint.map(row => row.EmployeeID);

    // Delete existing payslips
    Axios.post("https://api.payroll.functionparks.com/api/deletePayslips", { currentDate: currentDateFormatted, employeeIds })
      .then(response => {
        console.log('Deleted existing payslips:', response.data);
        generateNewPayslips(rowsToPrint);
      })
      .catch(error => {
        console.error('Error deleting existing payslips:', error);
        toast.error("Failed to delete existing payslips.");
      });
  };

  // const months = [
  //   'January', 'February', 'March', 'April', 'May', 'June',
  //   'July', 'August', 'September', 'October', 'November', 'December'
  // ];
  // const [selectedMonth, setSelectedMonth] = useState(0);


  return (
    <>
      <AdminSidebar />
      <AdminPayroll />
      <div style={{ overflowX: "auto", maxWidth: "100%", padding: "15px" }}>
        <div className="School" >

          <div className="tree">
            <TextField
              variant="outlined"
              label="Name & ID"
              value={searchQuery}
              onChange={handleSearchChange}
              style={{ marginLeft: "10px" }}/>
          </div>
          <div>
          <Typography variant="h6" style={{ margin: "20px 0" }}>
             Present Payroll: <strong>{currentDate.format('MMMM YYYY')}</strong> 
          </Typography>
          </div>

          <div className="plant">
             <Button variant="contained" color="primary" size="small" onClick={handleGeneratePDF} style={{marginRight:"20px"}}>
          Generate PDF
        </Button>

            <Button variant="contained" color="secondary" onClick={handleResetBonuses} >
              Reset Bonuses for New Month
            </Button>
          </div>
        </div>
        

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead style={{ backgroundColor: "rgb(167, 165, 165)" }}>
              <TableRow>
                <TableCell className="super-stickyCell">
                                <Checkbox
  checked={selectedRows.length === data.length && data.length > 0}
  onChange={handleSelectAll}
  indeterminate={selectedRows.length > 0 && selectedRows.length < data.length}
/>
                </TableCell>
                <TableCell className="super-stickyCell"> Employee ID
                </TableCell>
                <TableCell>Employee Name</TableCell>
                <TableCell>Basic/Month</TableCell>
                <TableCell>Gross Salary</TableCell>
                <TableCell>Deduction Salary</TableCell>
                <TableCell>Bonus</TableCell>
                <TableCell>Annual Salary</TableCell>
                <TableCell>Per Month</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {currentData.map((row, index) => (
    <TableRow key={index}>
        <TableCell className="super-stickyCell">
        <Checkbox
                      checked={selectedRows.some(selectedRow =>selectedRow.EmployeeID === row.EmployeeID)}
                      onChange={(event) => handleCheckboxChange(event, row)}/>
        </TableCell>
        <TableCell
            component="th"
            scope="row"
          className="super-stickyCell"
        >
            {row.EmployeeID}
        </TableCell>
        <TableCell>{row.DisplayName}</TableCell>
        <TableCell>{row.basicPerMonth}</TableCell>
        <TableCell>{row.GrossMonthly}</TableCell>
        <TableCell>{row.DeductionMonthly}</TableCell>
        <TableCell>{row.Bonus}</TableCell>
        <TableCell>{row.TotalSalary}</TableCell>
        <TableCell>{row.MonthlyTotal}</TableCell>
        <TableCell>
            <Button onClick={(event) => handleClick(event, row)}>
                <MoreHorizIcon />
            </Button>
        </TableCell>
    </TableRow>
))}
</TableBody>
</Table>
</TableContainer>

<div
    style={{
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "1rem",
    }}
>
<div>
    <Button disabled={currentPage === 1} onClick={handlePrevPage}>
        <FaChevronLeft />
    </Button>
    <span style={{ margin: "0 10px" }}>
        Page {currentPage} of {totalPages}
    </span>
    <Button
        disabled={currentPage === totalPages}
        onClick={handleNextPage}
    >
        <FaChevronRight />
    </Button>
</div>
<Typography variant="body2">
    {" "}
    <select
        value={rowsPerPage}
        onChange={handleRowsPerPageChange}
        style={{ width: "40px", height: "28px" }}
    >
        <option value={4}>4</option>
        <option value={8}>8</option>
        <option value={12}>12</option>
    </select>
</Typography>
</div>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          <MenuItem onClick={handleAddOpen}>Add</MenuItem>
        </Menu>
        <Dialog open={openAddDialog} onClose={handleAddClose}>
          <DialogTitle>Adding Bonus</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter the Bonus
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="EmployeeID"
              label="Employee ID"
              type="text"
              fullWidth
              variant="standard"
              value={newEmployee.EmployeeID}
              onChange={handleAddChange}
              readOnly
            />
            <TextField
              margin="dense"
              id="DisplayName"
              label="Employee Name"
              type="text"
              fullWidth
              variant="standard"
              value={newEmployee.DisplayName}
              onChange={handleAddChange}
              readOnly
            />
            <TextField
              margin="dense"
              id="Bonus"
              label="Bonus"
              type="text"
              fullWidth
              variant="standard"
              value={newEmployee.Bonus}
              onChange={handleAddChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddClose}>Cancel</Button>
            <Button onClick={handleAddSubmit}>Add</Button>
          </DialogActions>
        </Dialog>

        <Dialog
  open={confirmDialogOpen}
  onClose={handleCloseConfirmDialog}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">{"Confirm Action"}</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      Payslips for the following employees in this month already exist: {employeesWithExistingPayslips.join(", ")}. Do you want to delete the existing payslips and generate new ones?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseConfirmDialog} color="primary">
      Cancel
    </Button>
    <Button onClick={handleConfirmGeneratePDF} color="primary" autoFocus>
      Yes
    </Button>
  </DialogActions>
</Dialog>


      </div>
      <ToastContainer />
    </>
  );
};
export default PayrollData;

import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import HrSidebar from './HrSidebar';
import Week from './HrWeek';
import Axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
// import "./AdminCompany.css"

const DepartmentData = () => {

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
       if (!token) {
      navigate('/');
    } 
  }, []);
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'HrHome') {
        navigate('/');
      }
    }
  }, []);

  const [data, setData] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [addPositionOpen, setAddPositionOpen] = useState(false);
  const [typeDepartment, setTypeDepartment] = useState("");
  const [newdescription, setNewDescription] = useState("");
  const [newDepartmentID, setNewDepartmentID] = useState("");
  const [rowsPerPages, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorE1, setAnchorE1] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [newDepartmentName, setNewDepartmentName] = useState("");
  const [editedRow, setEditedRow] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const fetchData = (() => {
    Axios.get(`https://api.payroll.functionparks.com/HR/department/${username}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching pay data:", error);
      });
  });

  useEffect(() => {
    if(username) {
      fetchData();
    }
  }, [username]);

  const handleClick = (event, row) => {
    setAnchorE1(event.currentTarget);
    setSelectedRow(row);
  };
  const handleClose = () => {
    setAnchorE1(null);
    setSelectedRow(null);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      handleClose();
      const response = await Axios.put(
        `https://api.payroll.functionparks.com/api/depupdate/${editedRow.id}`,
        editedRow
      );
 
      toast.success("Record updated successfully");
      setData((prevData) =>
        prevData.map((item) => (item.id === editedRow.id ? editedRow : item))
      );
      fetchData();
      setEditDialogOpen(false);
      setEditedRow(null);
    } catch (error) {
      console.error("Error updating record:", error);
      toast.error("Failed to update record");
    }
  };
  const handleOpenPositionDialog = () => {
    setAddPositionOpen(true);
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleCloseAddPositionDialog = () => {
    setAddPositionOpen(false);
  };
  const handleDeleteCancelled = () => {
    setDeleteConfirmationOpen(false);
  };
  const handleDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleEditFormChange = (event, field) => {
    const { value } = event.target;
    setEditedRow((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
 
  const handleEditOpen = (row) => {
    setEditedRow(row);
    setEditDialogOpen(true);
  };

  const handleDeleteConfirmed = () => {
    if (selectedRow) {
      const idToDelete = selectedRow.id;
      Axios.delete(`https://api.payroll.functionparks.com/api/depdelete/${idToDelete}`).then (response => {
        const updatedData = data.filter(item => item.DepartmentId !== idToDelete);
        setData(updatedData);
        setDeleteConfirmationOpen(false);
        fetchData();
        handleClose();
        toast.success('Record deleted successfully');
      })
      .catch(error => {
        console.error('Error deleting record:', error); 
        toast.error('Failed to delete record');
      });
    }
  };
  
  const handleAddPosition = () => {
    if (!newDepartmentName) {
      toast.warning("Please fill DepartmentName")
      return;
    }
  
    console.log("Adding New Department...", {
      id: newDepartmentID,
      DepartmentName: newDepartmentName,
      Description: newdescription,
      officeId: username,
    });
  
    Axios.post("https://api.payroll.functionparks.com/api/department/post", {
      id: newDepartmentID,
      DepartmentName: newDepartmentName,
      Description: newdescription,
      officeId: username,
    })
      .then((response) => {
        console.log("New Department added Successfully:", response.data);
  
        const newPosition = {
          id: newDepartmentID,
          DepartmentName: newDepartmentName,
          Description: newdescription,
          officeId: username,
        };
  
        setData((prevData) => [...prevData, newPosition]);
        setTypeDepartment("");
        setNewDepartmentName("");
        setNewDescription("");
        handleCloseAddPositionDialog();
        fetchData();
        toast.success('Department added successfully');
      })
      .catch((error) => {
        console.error("Error adding new Department:", error);
        toast.error('Failed to add department');
      });
  };

  let filteredData = [...data];

  if (selectedType) {
    filteredData = filteredData.filter(
      (item) => item.DepartmentName === selectedType
    );
  }
  if (searchQuery) {
    filteredData = filteredData.filter((item) =>
      item.DepartmentName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  const totalPages = Math.ceil(filteredData.length / rowsPerPages);
  const startIndex = (currentPage - 1) * rowsPerPages;
  const endIndex = Math.min(startIndex + rowsPerPages, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <>
      <HrSidebar />
      <Week />
      <div style={{ overflowX: "auto", maxWidth: "100%", padding: "15px" }}>
        <div className="alien">
          <div className="mars">
            <TextField
              variant="outlined"
              label="Department Name"
              value={searchQuery}
              onChange={handleSearchChange}
              className="super.text"
              style={{ marginLeft: "10px" }}
            />
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead style={{ backgroundColor: "rgb(167, 165, 165)" }}>
              <TableRow>
                <TableCell>Department Name</TableCell>
                <TableCell>Description</TableCell>
                {/* <TableCell>Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.DepartmentName}</TableCell>
                  <TableCell>{row.Description ? row.Description : 'None' }</TableCell>
                  {/* <TableCell>
                    <Button onClick={(event) => handleClick(event, row)}>
                      <MoreHorizIcon />
                    </Button>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <div>
            <Button disabled={currentPage === 1} onClick={handlePrevPage}>
              <FaChevronLeft />
            </Button>
            <span style={{ margin: "0 10px" }}>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
            >
              <FaChevronRight />
            </Button>
          </div>
          <Typography variant="body2">
            {" "}
            <select
              value={rowsPerPages}
              onChange={handleRowsPerPageChange}
              style={{ width: "40px", height: "28px" }}
            >
              <option value={4}>4</option>
              <option value={8}>8</option>
              <option value={12}>12</option>
            </select>
          </Typography>
        </div>

        {selectedRow && (
          <Menu
            anchorEl={anchorE1}
            open={Boolean(anchorE1)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleEditOpen(selectedRow)}>Edit</MenuItem>
            <MenuItem onClick={handleDeleteConfirmation}>Delete</MenuItem>
          </Menu>
        )}

        <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
          <DialogTitle>Edit Details</DialogTitle>
          <DialogContent>
            <form onSubmit={handleFormSubmit}>
              <TextField
                label="Department Name"
                value={editedRow ? editedRow.DepartmentName : ""}
                onChange={(e) => handleEditFormChange(e, "DepartmentName")}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Description"
                value={editedRow ? editedRow.Description : ""}
                onChange={(e) => handleEditFormChange(e, "Description")}
                fullWidth
                margin="normal"
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditDialogOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleFormSubmit} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={deleteConfirmationOpen} onClose={handleDeleteCancelled}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this record?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancelled} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirmed} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer />
    </>
  );
};

export default DepartmentData;

import React, { useState } from "react";
import "./EmpPassword.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';

const Reset = () => {
  const [newPassword, setNewPassword] = useState("");
  const [reEnteredPassword, setReEnteredPassword] = useState("");
  
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== reEnteredPassword) {
      toast.error("New password and re-entered password do not match.");
      return;
    }
    
    try {
      const response = await axios.put(`https://api.payroll.functionparks.com/api/password`, {
        newPassword: newPassword
      });
      if (response.status === 200) {
        setNewPassword("");
        setReEnteredPassword("");
        toast.success("Password updated successfully!");
        navigate("/");
      } else {
        toast.error("Failed to update password. Please try again.");
      }
    } catch (error) {
      console.error("Error updating password:", error);
      toast.error("Failed to update password. Please try again.");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <>
      <div className="form-container" style={{ display: "block" }}>
        <form onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
          <div className="form-group">
            <label htmlFor="newPassword" className="form-label">
              New Password<span className="required">*</span>
            </label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              className="form-input"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="reEnteredPassword" className="form-label">
              Re-Enter New Password<span className="required">*</span>
            </label>
            <input
              type="password"
              id="reEnteredPassword"
              name="reEnteredPassword"
              className="form-input"
              value={reEnteredPassword}
              onChange={(e) => setReEnteredPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Save</button>
        </form>
      </div>
      
      <ToastContainer />
    </>
  );
};

export default Reset;

import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Menu,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
// import "./SuperLeaves.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuperNav from "./SuperNav";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode";
 
const DataGrid = () => {

  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");
 
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "SuperHome") {
        navigate("/");
      }
    }
  }, [navigate]);


  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [EditConfirm, seteditOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDetailsType, setSelectedDetailsType] = useState(null);
  const [data, setData] = useState([]);

  const fetchData = () => {
    axios.get("https://api.payroll.functionparks.com/api/details")
    .then((response) => {
      setData(response.data);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch data");
    });
  }
 
  useEffect(() => {
    fetchData();
  }, []);
 
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };
 
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null); 
  };
 
  const handleFormSubmit = (event) => {
    event.preventDefault();

    axios.put(`https://api.payroll.functionparks.com/api/details/${selectedRow.id}`, selectedRow)
      .then((response) => {
        fetchData();
        handleClose();
        handleEditClose();
        toast.success("Record updated successfully");
      })
      .catch((error) => {
        console.error("Error updating record:", error);
        toast.error("Failed to update record");
      });
  };
 
  const handleFormChange = (event) => {
    const { id, value } = event.target;
    setSelectedRow((prevSelectedRow) => ({
      ...prevSelectedRow,
      [id]: value,
    }));
  };
 
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
 
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
 
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
 
  const handleDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleEdit = () => {
    seteditOpen(true)
  }
 
  const handleDeleteConfirmed = () => {
    setDeleteConfirmationOpen(false); 
    if (selectedRow) {
      const idToDelete = selectedRow.id;
 
      axios.delete(`https://api.payroll.functionparks.com/api/details/${idToDelete}`)
        .then((response) => {
          const updatedData = data.filter((item) => item.id !== idToDelete);
          setData(updatedData);
          handleClose(); 
          toast.success("Record deleted successfully");
        })
        .catch((error) => {
          console.error("Error deleting record:", error);
          toast.error("Failed to delete record");
        });
    }
  };
 
  const handleDeleteCancelled = () => {
    setDeleteConfirmationOpen(false); 
  };

  const handleEditClose = () => {
    seteditOpen(false);
  }
 
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

 
  let filteredData = [...data];
 
  if (selectedDate) {
    filteredData = filteredData.filter((item) =>
      moment(item.Date).isSame(selectedDate, "day")
    );
  }
 
  if (selectedDetailsType !== null && selectedDetailsType !== "") {
    filteredData = filteredData.filter(
      (item) => item.DetailsType === selectedDetailsType
    );
  }
 
  if (searchQuery) {
    filteredData = filteredData.filter((item) =>
      item.ClientName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }
 
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);
 
  return (
    <>
      <SuperNav />
 
      <div style={{ overflowX: "auto", maxWidth: "100%", padding: "15px" }}>
        <div className="typo">
          <Typography
            variant="body2"
            style={{ width: "200px", marginBottom: "20px" }}
          >
            <input
              type="date"
              value={selectedDate}
              onChange={(e) => handleDateChange(e.target.value)}
              style={{ height: "56px" }}
            />
          </Typography>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead style={{ backgroundColor: "rgb(167, 165, 165)" }}>
              <TableRow>
                <TableCell className="super-stickyCell">Office ID</TableCell>
                <TableCell>Client Name</TableCell>
                <TableCell>Client Email</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Client Address</TableCell>
                <TableCell>Office Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Office Address</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell className="super-stickyCell">{row.OfficeID}</TableCell>
                  <TableCell>{row.ClientName}</TableCell>
                  <TableCell>{row.ClientEmail}</TableCell>
                  <TableCell>{row.PhoneNumber}</TableCell>
                  <TableCell>{row.ClientAddress}</TableCell>
                  <TableCell>{row.OfficeName}</TableCell>
                  <TableCell>{moment(row.Date).format("YYYY-MM-DD")}</TableCell>
                  <TableCell>{row.OfficeAddress}</TableCell>
                  <TableCell>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(event) => handleClick(event, row)}
                    >
                      <MoreHorizIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
 
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <div>
            <Button disabled={currentPage === 1} onClick={handlePrevPage}>
              <FaChevronLeft />
            </Button>
            <span style={{ margin: "0 10px" }}>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
            >
              <FaChevronRight />
            </Button>
          </div>
          <Typography variant="body2">
            {" "}
            <select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              style={{ width: "40px", height: "28px" }}
            >
              <option value={4}>4</option>
              <option value={8}>8</option>
              <option value={12}>12</option>
            </select>
          </Typography>
        </div>
 
        {selectedRow && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem onClick={handleDeleteConfirmation}>Delete</MenuItem>
          </Menu>
        )}

<Dialog open={EditConfirm} onClose={handleEditClose}>
  <DialogTitle>Edit Details</DialogTitle>
  <DialogContent>
    {selectedRow && (
      <form onSubmit={handleFormSubmit}>
        <TextField
          label="Office Id"
          id="OfficeID"
          value={selectedRow.OfficeID || ""}
          fullWidth
          margin="normal"
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="Client Name"
          id="ClientName"
          value={selectedRow.ClientName || ""}
          onChange={handleFormChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Client Email"
          id="ClientEmail"
          value={selectedRow.ClientEmail || ""}
          onChange={handleFormChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Phone Number"
          id="PhoneNumber"
          value={selectedRow.PhoneNumber || ""}
          onChange={handleFormChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Client Address"
          id="ClientAddress"
          value={selectedRow.ClientAddress || ""}
          onChange={handleFormChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Office Name"
          id="OfficeName"
          value={selectedRow.OfficeName || ""}
          onChange={handleFormChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Date"
          id="Date"
          type="date"
          value={moment(selectedRow.Date).format("YYYY-MM-DD") || ""}
          onChange={handleFormChange}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Office Address"
          id="OfficeAddress"
          value={selectedRow.OfficeAddress || ""}
          onChange={handleFormChange}
          fullWidth
          margin="normal"
        />
        <DialogActions>
          <Button onClick={handleEditClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Update
          </Button>
        </DialogActions>
      </form>
    )}
  </DialogContent>
</Dialog>


 
        <Dialog open={deleteConfirmationOpen} onClose={handleDeleteCancelled}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this record?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancelled} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirmed} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <ToastContainer />
      </div>
    </>
  );
};
 
export default DataGrid;
 
 
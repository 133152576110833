import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "../src/Login";

import AdminDashboard from "./Admin/Components/AdminDashboard";
import AdminOrgNav from "./Admin/Components/AdminOrgNav";
import AdminDayOff from "./Admin/Components/AdminDayOff";
import AdminPayroll from "./Admin/Components/AdminPayroll";
import AdminStatement from "./Admin/Components/AdminStatement";
import AdminPaying from "./Admin/Components/AdminPaying";
import AdminPaid from "./Admin/Components/AdminPaid";
import AdminPayment from "./Admin/Components/AdminPayment";
import AdminPeoples from "./Admin/Components/AdminPeoples";
import AdminNavbar from "./Admin/Components/AdminNavbar";
import AdminBasicDetails from "./Admin/Components/AdminBasicDetails";
import AdminBio from "./Admin/Components/AdminBio";
import AdminPlace from "./Admin/Components/AdminPlace";
import AdminAssetsType from "./Admin/Components/AdminAssetsType";
import AdminAvailabilty from "./Admin/Components/AdminAvailabilty";
import AdminTimeOff from "./Admin/Components/AdminTimeOff";
import AdminNoticePeriod from "./Admin/Components/AdminNoticePeriod";
import AdminCompany from "./Admin/Components/AdminCompany";
import AdminComponents from "./Admin/Components/AdminComponents";
import AdminTable from "./Admin/Components/AdminTable";

import HrHome from "./Hr/components/HR/HrHome";
import HrEmployee from "./Hr/components/HR/HrEmployee";
import HrAttendance from "./Hr/components/HR/HrAttendance";
import HrPayroll from "./Hr/components/HR/HrPayroll";
import HrPayslip from "./Hr/components/HR/HrPayslip";
import HrNavbarEmp from "./Hr/components/HR/HrNavbarEmp";
import HrJobDetails from "./Hr/components/HR/HrJobDetails";
import HrHolidays from "./Hr/components/HR/HrHolidays";
import HrOrganization from "./Hr/components/HR/HrOrganization";
import HrPosition from "./Hr/components/HR/HrPosition";
import HrAssets from "./Hr/components/HR/HrAssets";
import HrShift from "./Hr/components/HR/HrShift";
import HrWeekOff from "./Hr/components/HR/HrWeekOff";
import HrNotice from "./Hr/components/HR/HrNotice";
import HrPayrollData from "./Hr/components/HR/HrPayrollData";
import HrSuperadmin from "./Hr/components/HR/HrSuperadmin";
import HrAdmin from "./Hr/components/HR/HrAdmin";
import SendedSlip from "./Hr/components/HR/SendedSlip";
import MyProfile from "./Hr/components/HR/MyProfile";
import MyAttendance from "./Hr/components/HR/MyAttendance";
import MyLeave from "./Hr/components/HR/MyLeave";
import MyHrPay from "./Hr/components/HR/MyHrPay";
import HRLeave from "./Hr/components/HR/HrLeaveData"
import HrGender from "./Hr/components/HR/HrGender";
import HRLeavesDeclare from "./Hr/components/HR/HRLeavesDeclare";

import EmpHolidays from "./Employee/components/EmpHolidays";
// import EmpHome from "./Employee/components/EmployeeDashboard";
import EmpDashboard from "./Employee/components/EmployeeDashboard";
import EmpMyPay from "./Employee/components/EmpPayHeader";
import EmpProfile from "./Employee/components/EmpProfile";
import EmpLeave from "./Employee/components/EmpLeave";
import EmpAttendance from "./Employee/components/EmpAttendance";
import EmpNavbar from "./Employee/components/EmpNavbar";
import FileDownload from "./Employee/components/FileDownload";
import AllPayslips from "./Admin/Components/AllPayslips";
import Reset from "./Employee/components/Reset";
import LeavesDeclare from "./Admin/Components/LeavesDeclare";
import EmpDoc from "./Employee/components/EmpDoc";


import SuperHome from "./Super/SuperHome";
import SuperAdd from "./Super/SuperAdd";
import SuperDetails from "./Super/SuperDetails";

import AttendanceDetails from "./Admin/Components/AttendanceDetails";
import AttendanceCounts from "./Hr/components/HR/AttendanceCounts";
import Forgot from "./Forgot";
import Mouse from "./Mouse";
import Clock from "./Clock";
import Details from "./Hr/components/HR/Details";
import Count from "./Admin/Components/Count";
import AllLeaves from "./Admin/Components/AllLeaves";
import ApproveLeave from "./Hr/components/HR/ApproveLeave";
import Mistake from "./Admin/Components/Mistake";
import  {HighlightCriteriaProvider}  from './Hr/components/HR/HighlightCriteriaContext';
import Documents from "./Hr/components/HR/Documents";
import SalaryDetails from "./Admin/Components/SalaryDetails";


function App() {
  return (
    
    <div>
      <HighlightCriteriaProvider>
      
      <BrowserRouter>
        <Routes>
        
        <Route path="/" element={<Login/>}/>

           <Route path="/AdminHome" element={<AdminDashboard />} />
          <Route path="/Payroll" element={<AdminPayroll />} />
          <Route path="/Statement" element={<AdminStatement />} />
          <Route path="/adminPaying" element={<AdminPaying />} />
          <Route path="/adminPaid" element={<AdminPaid />} />
          <Route path="/Payment" element={<AdminPayment />} />
          <Route path="/Navbar" element={<AdminNavbar />} />
          <Route path="/AdminBasicDetails" element={<AdminBasicDetails />} />
          <Route path="/Bio" element={<AdminBio />} />
          <Route path="/OrgNav" element={<AdminOrgNav />} />
          <Route path="/adminCompany" element={<AdminCompany />} />
          <Route path="/adminPeoples" element={<AdminPeoples />} />
          <Route path="/adminDayOff" element={<AdminDayOff />} />
          <Route path="/Place" element={<AdminPlace />} />
          <Route path="/AssetsType" element={<AdminAssetsType />} />
          <Route path="/Availabilty" element={<AdminAvailabilty />} />
          <Route path="/TimeOff" element={<AdminTimeOff />} />
          <Route path="/NoticePeriod" element={<AdminNoticePeriod />} />
          <Route path="/AdminComponents" element={<AdminComponents />} /> 
          <Route path="/LeavesDeclare" element={<LeavesDeclare />} /> 
          <Route path="/AllPayslips" element={<AllPayslips />} /> 
          <Route path="/AttendanceDetails" element={<AttendanceDetails />} /> 
          <Route path="/Count" element={<Count />} /> 
          <Route path="/AllLeaves" element={<AllLeaves />} /> 
          <Route path="/Mistake" element={<Mistake />} /> 
          <Route path="/AdminTable" element={<AdminTable />} /> 
          <Route path="/SalaryDetails" element={<SalaryDetails />} /> 


           <Route path="/HrHome" element={<HrHome />} />
          <Route path="/hrEmployee" element={<HrEmployee />} />
          <Route path="/hrAttendance" element={<HrAttendance />} />
          <Route path="/hrLeaveData" element={<HRLeave />} />
          <Route path="/hrPayroll" element={<HrPayroll />} />
          <Route path="/hrPayrollData" element={<HrPayrollData />} />
          <Route path="/hrPayslip" element={<HrPayslip />} />
          <Route path="/hrNavbarEmp" element={<HrNavbarEmp />} />
          <Route path="/hrJobDetails" element={<HrJobDetails />} />
          <Route path="/hrHolidays" element={<HrHolidays />} />
          <Route path="/hrOrganization" element={<HrOrganization />} />
          <Route path="/hrPosition" element={<HrPosition />} />
          <Route path="/hrAssets" element={<HrAssets />} />
          <Route path="/hrShift" element={<HrShift />} />
          <Route path="/hrWeekOff" element={<HrWeekOff />} />
          <Route path="/hrNotice" element={<HrNotice />} />
          <Route path="/hrSuperadmin" element={<HrSuperadmin />} />
          <Route path="/hrAdmin" element={<HrAdmin />} />
          <Route path="/SendedSlip" element={<SendedSlip />} />
          <Route path="/MyProfile" element={<MyProfile />} />
          <Route path="/MyAttendance" element={<MyAttendance />} />
          <Route path="/MyLeave" element={<MyLeave />} />
          <Route path="/MyHrPay" element={<MyHrPay/>}/>
          <Route path="/AttendanceCount" element={<AttendanceCounts/>}/>
          <Route path="/HRLeavesDeclare" element={<HRLeavesDeclare/>}/>
          <Route path="/HrGender" element={<HrGender/>}/>
          <Route path="/Details" element={<Details/>}/>
          <Route path="/ApproveLeave" element={<ApproveLeave/>}/>
          <Route path="/Documents" element={<Documents/>}/>


          <Route path="/EmployeeDashboard" element={<EmpDashboard />} /> 
          <Route path="/EmpPayHeader" element={<EmpMyPay />} />
          <Route path="/EmpHolidays" element={<EmpHolidays />} />
          <Route path="/EmpProfile" element={<EmpProfile />} />
          <Route path="/EmpLeave" element={<EmpLeave />} />
          <Route path="/EmpAttendance" element={<EmpAttendance />} />
          <Route path="/EmpNavbar" element={<EmpNavbar />} />
          <Route path="/FileDownload" element={<FileDownload />} />
          <Route path="/Reset" element={<Reset />} />
          <Route path="/ForgotPassword" element={<Forgot />} />
          <Route path="/EmpDoc" element={<EmpDoc />} />

          <Route path="/SuperHome" element={<SuperHome/>}/>
          <Route path="/SuperAdd" element={<SuperAdd/>}/>
          <Route path="/SuperDetails" element={<SuperDetails/>}/>

          <Route path="/mouse" element={<Mouse/>}/>
          <Route path="/clock" element={<Clock/>}/>

          

        </Routes>
      </BrowserRouter>

      </HighlightCriteriaProvider>
    </div>
  );
}

export default App; 

import "./AdminBasicDetails.css";
import Navbar from "./AdminNavbar";
import AdminSidebar from "./AdminSidebar";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import React, { useState, useEffect } from "react";

const AdminBasicDetails = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "AdminHome") {
        navigate("/");
      }
    }
  }, []);

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [gender, setGender] = useState("");
  const [role, setRole] = useState("");
  const [dateofbirth, setDateOfBirth] = useState("");
  const [email, setEmail] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [joiningdate, setJoiningDate] = useState("");
  const [jobtitle, setJobTitle] = useState("");
  const [jobdepartment, setJobDepartment] = useState("");
  const [shifts, setShifts] = useState("");
  const [weeklyoffs, setWeeklyOffs] = useState("");
  const [FlatNumber, setHouseNumber] = useState("");
  const [city, setCity] = useState("");
  const [State, setSelectedState] = useState("");
  const [PostalCode, setPostalCode] = useState("");
  const [noticeperiod, setNoticePeriod] = useState("");
  const [payroll, setPayroll] = useState("");
  const [isHovered, setIsHovered] = useState(true);
  const [reportingTo, setReportingTo] = useState("");
  const [teamLead, setTeamLead] = useState("");
  const [manager, setManager] = useState("");
  const [showManagerDropdown, setShowManagerDropdown] = useState(false);
  const [showTeamLeadDropdown, setShowTeamLeadDropdown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const indiaStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Other",
  ];

  const handleReportingChange = (e) => {
    const selectedOption = e.target.value;
    setReportingTo(selectedOption);
    
    setShowManagerDropdown(selectedOption === "Manager");
    
    setShowTeamLeadDropdown(selectedOption === "Team Lead");
  };


  useEffect(() => {
    setDisplayName(
      `${firstName.trim()} ${middleName.trim()} ${lastName.trim()}`
    );
  }, [firstName, middleName, lastName]);

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const [departmentData, setDepartmentData] = useState([]);
  const [positionData, setPositionData] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [weekData, setWeekData] = useState([]);
  const [noticeData, setNoticeData] = useState([]);
  const [basicSalary, setBasicSalary] = useState("");
  const [totalSalary, setTotalSalary] = useState("");
  const [monthly, setMonthly] = useState("");
  const [payrollType, setPayrollType] = useState("");
  const [payComponents, setPayComponents] = useState([]);
  const [payrollTypes, setPayrollTypes] = useState([]);
  const [typeName, setTypeName] = useState("");
  const [user, setUser] = useState([]);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmail(email);
    setIsEmailValid(validateEmail(email));
  };

  const handlePhoneNumberChange = (e) => {
    const phoneNumber = e.target.value;
    setPhoneNumber(phoneNumber);
    setIsPhoneNumberValid(validatePhoneNumber(phoneNumber));
  };

  useEffect(() => {
    if (username) {
      fetchPayrollTypes();
      fetchPayComponents();
    }
  }, [username]);

  useEffect(() => {
    const fetchnameData = async () => {
      try {
        const response = await Axios.get(
          `https://api.payroll.functionparks.com/loginnuser/${username}`
        );
        const loggedInUser = response.data.user;
        setUser(loggedInUser);
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };
    fetchnameData();
  }, [username]);

  const fetchPayrollTypes = () => {
    Axios.get(`https://api.payroll.functionparks.com/api/data/type/${username}`)
      .then((response) => {
        setPayComponents([]); 
        setPayrollType("");
        setPayrollTypes(response.data);
      })
      .catch((error) => {
        console.error("Error fetching payroll types:", error);
        toast.error("Failed to fetch payroll types");
      });
  };

  const fetchPayComponents = (payrollType) => {
    Axios.get(`https://api.payroll.functionparks.com/api/paycomponents/${payrollType}/${username}`)
      .then((response) => {
        setPayComponents(response.data);
      })
      .catch((error) => {
        console.error("Error fetching pay components:", error);
        toast.error("Failed to fetch pay components");
      });
  };

  const handlePayrollTypeChange = (e) => {
    const selectedPayrollType = e.target.value;
    setPayrollType(selectedPayrollType);
    const selectedType = payrollTypes.find(type => type.PayComponents === selectedPayrollType);
    setTypeName(selectedType?.TypeName || ""); 
    fetchPayComponents(selectedPayrollType);
  };

  const handleDepartmentTypeChange = (e) => {
    const selectedType = e.target.value;
    setJobDepartment(selectedType);
  };
  const fetchDataDepartment = () => {
    Axios.get(`https://api.payroll.functionparks.com/api/department/${username}`)
      .then((response) => {
        setDepartmentData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Department data:", error);
        toast.error("Failed to fetch Department data");
      });
  };

  const handlePositionTypeChange = (e) => {
    const selectedType = e.target.value;
    setJobTitle(selectedType);
  };
  const fetchDataDepartment1 = () => {
    Axios.get(`https://api.payroll.functionparks.com/api/positions/${username}`)
      .then((response) => {
        setPositionData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Position data:", error);
        toast.error("Failed to fetch Position data");
      });
  };

  const handleShiftTypeChange = (e) => {
    const selectedType = e.target.value;
    setShifts(selectedType);
  };
  const fetchDataDepartment2 = () => {
    Axios.get(`https://api.payroll.functionparks.com/api/shift/type/${username}`)
      .then((response) => {
        setShiftData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Shift data:", error);
        toast.error("Failed to fetch Shift data");
      });
  };

  const handleWeekTypeChange = (e) => {
    const selectedType = e.target.value;
    setWeeklyOffs(selectedType);
  };
  const fetchDataDepartment3 = () => {
    Axios.get(`https://api.payroll.functionparks.com/api/weekoff/type/${username}`)
      .then((response) => {
        setWeekData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Week data:", error);
        toast.error("Failed to fetch Week data");
      });
  };

  const handleNoticeTypeChange = (e) => {
    const selectedType = e.target.value;
    setNoticePeriod(selectedType);
  };
  const fetchDataDepartment4 = () => {
    Axios.get(`https://api.payroll.functionparks.com/api/Notice/type/${username}`)
      .then((response) => {
        setNoticeData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Notice data:", error);
        toast.error("Failed to fetch Notice data");
      });
  };

  useEffect(() => {
    if(username) {
      fetchDataDepartment();
      fetchDataDepartment1();
      fetchDataDepartment2();
      fetchDataDepartment3();
      fetchDataDepartment4();
    }
  }, [username]);

  const submitButton = () => {
        
    if (!firstName || !lastName || !gender || !dateofbirth || !email || !phonenumber ||
          !FlatNumber || !city || !State || !PostalCode || !joiningdate || !jobdepartment ||
          !jobtitle || !role || !shifts || !weeklyoffs || !basicSalary || !noticeperiod || !reportingTo || !payrollType) {
        toast.warning('Please fill in all mandatory fields.');
        return;
      }
    setIsSubmitting(true);  

    let selectedReporting = "";
    if (reportingTo === "Manager") {
      selectedReporting = manager;
    } else if (reportingTo === "Team Lead") {
      selectedReporting = teamLead;
    }

    if (!reportingTo) {
      toast.error("Please select Reporting To");
      return;
    }

    Axios.post("https://api.payroll.functionparks.com/Details/insert", {
      FirstName: firstName,
      middleName,
      lastName,
      displayName,
      gender,
      dateofbirth,
      email,
      phonenumber,
      FlatNumber, 
      city, 
      State, 
      PostalCode,
      Joiningdate: joiningdate,
      Jobtitle: jobtitle,
      Jobdepartment: jobdepartment,
      Role: role,
      Shifts: shifts,
      Weeklyoffs: weeklyoffs,
      BasicSalary: basicSalary,
      Noticeperiod: noticeperiod,
      reportingTo,
      reportingTo,
      selectedReporting,
      totalSalary,
      monthly,
      payComponents,
      typeName,
      username,
    })
    .then((response) => {
      setTotalSalary(response.data.totalSalary);
      setMonthly(response.data.monthly);
      toast.success("Employee added successfully");
      setFirstName("");
      setMiddleName("");
      setLastName("");
      setDisplayName("");
      setGender("");
      setDateOfBirth("");
      setEmail("");
      setPhoneNumber("");
      setJoiningDate("");
      setJobTitle("");
      setJobDepartment("");
      setRole("");
      setShifts("");
      setWeeklyOffs("");
      setHouseNumber("");
      setCity("");
      setSelectedState("");
      setPostalCode("");
      setNoticePeriod("");
      setPayroll("");
      setReportingTo("");
      setTeamLead("");
      setManager("");
      setBasicSalary("");
      setPayrollType("");
      setPayComponents([]);
    })
    .catch((error) => {
      console.error("Error submitting data:", error);
      toast.error("Failed to add employee");
    })
    .finally(() => {
      setIsSubmitting(false);
    });
  };

  const metroCities = ["Delhi", "Mumbai", "Kolkata", "Chennai", "Bengaluru", "Hyderabad", "Pune", "Ahmedabad"];

  return (
    <>
      <AdminSidebar />
      <div className="container-fluid EmployeeManagement">
        <Navbar />

        <div className="row empDetails">
          <form action="" method="post" className="col-lg-12">
            <div className="row ms-2 mb-3 me-2">
              <div className="col-sm-3">
                <label htmlFor="FName" className="col-form-label  empLabel">
                  First Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="empId"
                  name="empId"
                  className="form-control custom-input"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </div>
              <div className="col-sm-3">
                <label htmlFor="mName" className=" col-form-label  empLabel">
                  Middle Name
                </label>
                <input
                  type="text"
                  id="mName"
                  name="mName"
                  className="form-control custom-input"
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                />
              </div>

              <div className="col-sm-3">
                <label htmlFor="lName" className=" col-form-label  empLabel">
                  Last Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="lName"
                  name="lName"
                  className="form-control custom-input"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
              <div className="col-sm-3">
                <label htmlFor="dName" className=" col-form-label  empLabel">
                  Display Name
                </label>

                <input
                  type="text"
                  id="dName"
                  name="dName"
                  className="form-control custom-input"
                  value={displayName}
                  onChange={(e) => {
                    setDisplayName(e.target.value);
                  }}
                  readOnly
                />
              </div>
            </div>

            <div className="row ms-2 mb-3 me-2">
              
              <div className="col-sm-3">
                <label htmlFor="gender" className="col-form-label  empLabel">
                  Gender<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="gender"
                  name="gender"
                  value={gender}
                  className="form-select custom-select form-control "
                  onChange={(e) => {
                    setGender(e.target.value);
                  }}
                  required
                >
                  <option value="" disabled selected>
                    Select Gender
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>

              <div className="col-sm-3">
                <label htmlFor="dob" className=" col-form-label  empLabel">
                  Date Of Birth<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  id="dob"
                  name="dob"
                  value={dateofbirth}
                  className="form-control custom-input"
                  onChange={(e) => {
                    setDateOfBirth(e.target.value);
                  }}
                  required
                />
              </div>

              <div className="col-sm-3">
        <label htmlFor="email" className="col-form-label empLabel">
          EMail<span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          className={`form-control custom-input ${!isEmailValid ? "is-invalid" : ""}`}
          onChange={handleEmailChange}
          required
        />
        {!isEmailValid && (
          <div className="invalid-feedback">
            Please enter a valid email address.
          </div>
        )}
      </div>

      <div className="col-sm-3">
        <label htmlFor="tel" className="col-form-label empLabel">
          Phone Number<span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="tel"
          id="tel"
          name="tel"
          value={phonenumber}
          className={`form-control custom-input ${!isPhoneNumberValid ? "is-invalid" : ""}`}
          onChange={handlePhoneNumberChange}
          required
        />
        {!isPhoneNumberValid && (
          <div className="invalid-feedback">
            Please enter a valid 10-digit phone number.
          </div>
        )}
      </div>
            </div>

            <div className="row ms-2 mb-3 me-2">
              <div className="col-sm-3">
                <label
                  htmlFor="reportingTo"
                  className="col-form-label empLabel"
                >
                  Reporting to<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="reportingTo"
                  name="reportingTo"
                  className="form-select custom-select form-control"
                  onChange={handleReportingChange}
                  value={reportingTo}
                  required
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  <option value="Team Lead">Team Lead</option>
                  <option value="Manager">Manager</option>
                  <option value="CEO">CEO</option>
                </select>
              </div>

              <div className="col-sm-3">
                {showManagerDropdown && (
                  <>
                    <label
                      htmlFor="manager"
                      className="col-form-label empLabel"
                    >
                      Manager:
                    </label>
                    <select
                      id="manager"
                      value={manager}
                      className="form-select custom-select form-control"
                      onChange={(e) => {
                        setManager(e.target.value);
                      }}
                    >
                      <option value="" disabled selected>
                        Select Name
                      </option>
                      <option value="Manager A">Manager A</option>
                      <option value="Manager B">Manager B</option>
                      <option value="Manager C">Manager C</option>
                    </select>
                  </>
                )}
                {showTeamLeadDropdown && (
                  <>
                    <label
                      htmlFor="teamLead"
                      className="col-form-label empLabel"
                    >
                      Team Lead:
                    </label>
                    <select
                      id="teamLead"
                      value={teamLead}
                      className="form-select custom-select form-control"
                      onChange={(e) => {
                        setTeamLead(e.target.value);
                      }}
                    >
                      <option value="" disabled selected>
                        Select Lead
                      </option>
                      <option value="Team Lead A">Team Lead A</option>
                      <option value="Team Lead B">Team Lead B</option>
                      <option value="Team Lead C">Team Lead C</option>
                    </select>
                  </>
                )}
              </div>
            </div>

            <label
              htmlFor="Payroll"
              className="col-form-label  jobLabel my-3 ms-3  text-primary"
            >
              Employee Address
            </label>

            <div className="row  ms-2 me-2 mb-3">
              <div className="col-sm-3">
                <label htmlFor="Flat" className=" col-form-label  empLabel ">
                  Flat / House no / Area<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="Flat"
                  name="Flat"
                  value={FlatNumber}
                  className="form-control custom-input"
                  onChange={(e) => {
                    setHouseNumber(e.target.value);
                  }}
                  required
                />
              </div>

                    <div className="col-sm-3">
        <label htmlFor="city" className="col-form-label empLabel">
          City<span style={{ color: "red" }}>*</span>
        </label>
        <select
          id="city"
          name="city"
          className="form-select form-control custom-select"
          onChange={(e) => {
            setCity(e.target.value);
          }}
          value={city}
          required
        >
          <option value="" disabled>Select a City</option>
          {metroCities.map((city, index) => (
            <option key={index} value={city}>
              {city}
            </option>
          ))}
          <option value="Other">Other</option>
        </select>
      </div>

              <div className="col-sm-3">
                <label htmlFor="State" className="col-form-label empLabel">
                  State<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="State"
                  name="State"
                  className="form-select form-control custom-select"
                  onChange={handleStateChange}
                  value={State}
                  required
                >
                  <option value="" disabled>Select a state</option>
                  {indiaStates.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-3">
                <label htmlFor="PostalCode" className="col-form-label  empLabel">
                  Postal Code<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="PostalCode"
                  name="PostalCode"
                  value={PostalCode}
                  className="form-control custom-input "
                  onChange={(e) => {
                    setPostalCode(e.target.value);
                  }}
                  required
                />
              </div>
            </div>

            <label
              htmlFor="Payroll"
              className="col-form-label  jobLabel my-3 ms-3  text-primary"
            >
              Job Details
            </label>

            <div className="row  ms-2 me-2 mb-3">
              <div className="col-sm-3">
                <label htmlFor="jDate" className=" col-form-label  empLabel ">
                  Joining Date<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  id="jDate"
                  name="jDate"
                  value={joiningdate}
                  className="form-control custom-input"
                  onChange={(e) => {
                    setJoiningDate(e.target.value);
                  }}
                  required
                />
              </div>

              <div className="col-sm-3">
                <label htmlFor="jobDep" className=" col-form-label  empLabel">
                  Department<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="jobDep"
                  name="jobDep"
                  value={jobdepartment}
                  className="form-select form-control custom-select"
                  onChange={handleDepartmentTypeChange}
                  required
                >
                  <option value="" disabled selected>
                    Select Department
                  </option>
                  {departmentData.map((department, index) => (
                    <option key={index} value={department.DepartmentName}>
                      {department.DepartmentName}
                    </option>
                  ))}
                  <option>None</option>
                </select>
              </div>

              <div className="col-sm-3">
                <label htmlFor="jobTitle" className=" col-form-label  empLabel">
                  Position<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="jobTitle"
                  name="jobTitle"
                  value={jobtitle}
                  className="form-select form-control custom-select"
                  onChange={handlePositionTypeChange}
                  required
                >
                  <option value="" disabled selected>
                    Select job title
                  </option>
                  {positionData.map((position, index) => (
                    <option key={index} value={position.PositionName}>
                      {position.PositionName}
                    </option>
                  ))}
                  <option>None</option>
                </select>
              </div>

              <div className="col-sm-3">
                      <label htmlFor="Role" className="col-form-label  empLabel">
                        Role<span style={{color:'red'}}>*</span>
                        </label>
                      <select id="Role" name="Role" 
                      value={role} className="form-select form-control custom-select " 
                      onChange={(e) => {setRole(e.target.value)}}
                      required>
                      <option value=""  disabled selected>Select Role</option>
                      <option value="EmployeeDashboard">EmployeeDashboard</option>
                      <option value="HrHome">HrHome</option>
                      {/* <option value="AdminHome">AdminHome</option>
                      <option value="Guest">Guest</option> */}
                      </select>
                </div>
            </div>

            <div className="row  ms-2 me-2 mb-3">
              <div className="col-sm-3">
                <label htmlFor="shifts" className="col-form-label  empLabel">
                  Shifts<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="shifts"
                  name="shifts"
                  value={shifts}
                  className="form-select form-control custom-select"
                  onChange={handleShiftTypeChange}
                  required
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  {shiftData.map((shift, index) => (
                    <option key={index} value={shift.ShiftName}>
                      {shift.ShiftName}
                    </option>
                  ))}
                  <option>None</option>
                </select>
              </div>
              <div className="col-sm-3">
                <label
                  htmlFor="weekly offs"
                  className="col-form-label  empLabel"
                >
                  Weekly Offs<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="weekly offs"
                  name="weekly offs"
                  value={weeklyoffs}
                  className="form-select form-control custom-select"
                  onChange={handleWeekTypeChange}
                  required
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  {weekData.map((week, index) => (
                    <option key={index} value={week.Day}>
                      {week.Day}
                    </option>
                  ))}
                  <option>None</option>
                </select>
              </div>

              <div className="col-sm-3">
                <label htmlFor="BasicSalary" className=" col-form-label  empLabel">
                  Basic Salary<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="BasicSalary"
                  name="BasicSalary"
                  value={basicSalary}
                  className="form-control custom-input"
                  // placeholder="Enter Salary"
                  onChange={(e) => {
                    setBasicSalary(e.target.value);
                  }}
                  required/>
              </div>
              <div className="col-sm-3">
                <label
                  htmlFor="noticePeriod"
                  className="col-form-label  empLabel"
                >
                  Notice period<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  name="noticePeriod"
                  id="noticePeriod"
                  value={noticeperiod}
                  className="form-select form-control custom-select"
                  onChange={handleNoticeTypeChange}>

                  <option value="" disabled selected>
                    Notice Period
                  </option>
                  {noticeData.map((Notice, index) => (
                    <option key={index} value={Notice.NoticePeriodDuration}>
                      {Notice.NoticePeriodDuration}
                    </option>
                  ))}
                  <option>None</option>
                </select>
              </div>
            </div>
           
            <div className="row ms-2 me-2 mb-3">
        <div className="col-sm-12">
          <h2 className="col-form-label jobLabel my-3 text-primary">Payroll</h2>
          <br />
          <label htmlFor="payroll" className="col-form-label empLabel">
            Payroll Type:
          </label>
          <select
            id="payroll"
            onChange={handlePayrollTypeChange}
            className="dropdown"
            value={payrollType}
          >
            <option value="" disabled>
              Select type
            </option>
            {payrollTypes.map((type, index) => (
              <option key={index} value={type.PayComponents}>
                {type.TypeName}
              </option>
            ))}
            <option>None</option>
          </select>
        </div>
      </div>
          </form>
        </div>

        <button
          onClick={submitButton}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            backgroundColor: "#007bff",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "6px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "16px",
            margin: "20px auto",
            display: "block",
            transition: "background-color 0.3s ease",
            transform: isHovered ? "scale(1.05)" : "scale(1)",
          }}
          disabled={isSubmitting}>
          {isSubmitting ? "Saving..." : "Save"}
        </button>

        <ToastContainer />
      </div>
    </>
  );
};

export default AdminBasicDetails;

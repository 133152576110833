// HighlightCriteriaContext.js
import React, { createContext, useContext, useState } from 'react';

const HighlightCriteriaContext = createContext();

export const useHighlightCriteria = () => useContext(HighlightCriteriaContext);

export const HighlightCriteriaProvider = ({ children }) => {
  const [highlightCriteria, setHighlightCriteria] = useState([]);

  return (
    <HighlightCriteriaContext.Provider value={{ highlightCriteria, setHighlightCriteria }}>
      {children}
    </HighlightCriteriaContext.Provider>
  );
};

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { jwtDecode } from "jwt-decode";
import {useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

const Example = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
       if (!token) {
      navigate('/');
    } 
  }, []);
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'HrHome') {
        navigate('/');
      }
    }
  }, []);
  
  const [averageAttendance, setAverageAttendance] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.payroll.functionparks.com/api/average/attendance/${username}`);
        const attendanceData = response.data.map((entry) => ({
          ...entry,
          AveragePercentage: parseFloat(entry.AveragePercentage),
          Month: getMonthName(entry.Month), // Convert month number to name
        }));

        // Fill missing months with zero attendance
        const allMonthsData = fillMissingMonths(attendanceData);
        console.log('Attendance Data:', allMonthsData);
        setAverageAttendance(allMonthsData);
      } catch (error) {
        console.error('Error fetching attendance data:', error);
      }
    };

    fetchData();
  }, [username]);

  const getMonthName = (monthNumber) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[monthNumber - 1] || '';
  };

  const fillMissingMonths = (data) => {
    const monthsData = Array.from({ length: 12 }, (_, index) => {
      const monthName = getMonthName(index + 1);
      const existingMonthData = data.find(item => item.Month === monthName);

      if (existingMonthData) {
        return existingMonthData;
      } else {
        return {
          Month: monthName,
          AveragePercentage: 0,
        };
      }
    });

    return monthsData;
  };

  return (
    <ResponsiveContainer width="100%" height={250}>
      <h4 className="text-primary shadow-sm p-2 mb-4 bg-body rounded">
              Attendance Percentage
            </h4>
      <BarChart
        width={600}
        height={250}
        data={averageAttendance}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Month" />
        <YAxis />
        <Tooltip />
        {/* <Legend /> */}
        <Bar dataKey="AveragePercentage" stackId="a" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Example;
